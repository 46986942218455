import { NgModule } from '@angular/core';
import { PreloadAllModules, Routes, RouterModule } from '@angular/router';

import { theme } from '../environments/theme';

const routes: Routes = [
    ...theme.customRoutes,
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'list',
        loadChildren: () => import('./list/list.module').then(m => m.ListPageModule)
    },
    {
        path: 'home',
        pathMatch: 'prefix',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule)
    },
    {
        path: 'keywords/:view',
        loadChildren: () => import('./pages/keywords/keywords.module').then(m => m.KeywordsPageModule)
    },
    {
        path: 'preview',
        loadChildren: () => import('./pages/preview/preview.module').then(m => m.PreviewPageModule)
    },
    {
        path: 'preview-attendee-detail',
        loadChildren: () => import('./pages/preview-attendee-detail/preview-attendee-detail.module').then(m => m.PreviewAttendeeDetailPageModule)
    },
    {
        path: 'preview/:segment/:view',
        loadChildren: () => import('./pages/preview/preview.module').then(m => m.PreviewPageModule)
    },
    {
        path: 'appointments',
        loadChildren: () => import('./pages/appointments/appointments.module').then(m => m.AppointmentsPageModule)
    },
    {
        path: 'appointments/:id',
        loadChildren: () => import('./pages/appointments/appointments.module').then(m => m.AppointmentsPageModule)
    },
    {
        path: 'timeslots',
        loadChildren: () => import('./pages/timeslots/timeslots.module').then(m => m.TimeslotsPageModule)
    },
    {
        path: 'ask-the-speaker',
        loadChildren: () => import('./pages/ask-the-speaker/ask-the-speaker.module').then(m => m.AskTheSpeakerPageModule)
    },
    {
        path: 'edit-profile',
        loadChildren: () => import('./pages/edit-profile/edit-profile.module').then(m => m.EditProfilePageModule)
    },
    {
        path: 'setting',
        loadChildren: () => import('./pages/setting/setting.module').then(m => m.SettingPageModule)
    },
    {
        path: 'mail-setting',
        loadChildren: () => import('./pages/setting/setting.module').then(m => m.SettingPageModule)
    },
    {
        path: 'no-event',
        loadChildren: () => import('./pages/no-event/no-event.module').then(m => m.NoEventPageModule)
    },
    {
        path: 'event-key',
        loadChildren: () => import('./pages/event-key/event-key.module').then(m => m.EventKeyPageModule)
    },
    {
        path: 'access-key',
        loadChildren: () => import('./pages/event-key/event-key.module').then(m => m.EventKeyPageModule)
    },
    {
        path: 'access-key/:event-key',
        loadChildren: () => import('./pages/event-key/event-key.module').then(m => m.EventKeyPageModule)
    },
    {
        path: 'messages',
        loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesPageModule)
    },
    {
        path: 'messages/channels',
        loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesPageModule)
    },
    {
        path: 'messages/broadcast',
        loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesPageModule)
    },
    {
        path: 'messages/channel/:channelId',
        loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesPageModule)
    },
    {
        path: 'messages/direct/:participantId',
        loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesPageModule)
    },
    {
        path: '',
        loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
    },
    {
        path: 'live-votings',
        loadChildren: () => import('./pages/live-votings/live-votings.module').then(m => m.LiveVotingsPageModule)
    },
    {
        path: 'credentials-wallet',
        loadChildren: () => import('./pages/credentials-wallet/credentials-wallet.module').then(m => m.CredentialsWalletPageModule)
    },
    {
        path: 'deeplink',
        loadChildren: () => import('./pages/deeplink/deeplink.module').then(m => m.DeeplinkPageModule)
    },
    {
        path: 'unsubscribe',
        loadChildren: () => import('./pages/unsubscribe/unsubscribe.module').then(m => m.UnsubscribePageModule)
    },
    {
        path: '**',
        redirectTo: 'login',
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        preloadingStrategy: PreloadAllModules,
        // relativeLinkResolution: 'legacy'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
