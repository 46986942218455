import { Events } from "../helpers/events";
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { NavController } from '@ionic/angular';

// rxjs
import { take } from "rxjs/operators";

import { ServiceLocatorService } from "../services/service-locator.service";
import { RouterExtService } from "../services/router-ext.service";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { OverlayService } from "../services/overlay.service";
import { PlatformService } from "../services/platform.service";
import { EventService } from "../services/event.service";
import { UserService } from "../services/user.service";
import { MarketplaceService } from "../services/marketplace.service";
import { ParticipantService } from "../services/participant.service";

import { AttendeeDetailComponent } from "../components/attendee-detail/attendee-detail.component";
import { MarketplaceDetailComponent } from '../components/marketplace-detail/marketplace-detail.component';

// models
import { Event } from "../models/event";
import { Participant } from "../models/participant";
import { User } from "../models/user";
import { Marketplace } from "../models/marketplace";
import { MarketplacePost } from "../models/marketplace-post";

// config
import { environment } from "../../environments/environment";

import { getThemeConfig } from './config';
import { LiveVotingComponent } from "../components/live-voting/live-voting.component";
import { VideoDetailComponent } from "../components/video-detail/video-detail.component";
import { MarketplaceAdDetailComponent } from "../components/marketplace-ad-detail/marketplace-ad-detail.component";

export class BasePage {
    /**
     * chat box
     *
     * @type ViewChild
     */
    //@ViewChild(Content) pageContent: Content;

    /**
     * translate
     *
     * @type TranslateService
     */
    public translate: TranslateService;

    /**
     * app events
     *
     * @type Events
     */
    public appEvents: Events;

    /**
     * toast service
     *
     * @type OverlayService
     */
    public overlayService: OverlayService;

    /**
     * platform service
     *
     * @type PlatformService
     */
    public plt: PlatformService;

    /**
     * modal controller service
     *
     * @type ModalController
     */
    public modalController: ModalController;

    /**
     * modal controller service
     *
     * @type ModalController
     */
    public routerExtService: RouterExtService;

    /**
     * participant service
     */
    public participantService: ParticipantService;

    /**
     * user service
     */
    public eventService: EventService;

    /**
     * user service
     */
    public userService: UserService;

    /**
     * markeptlace service
     */
    public marketplaceService: MarketplaceService;

    /**
     * NavController
     */
    public nav: NavController;

    /**
     * active event for user
     *
     * @type Event
     */
    public event: Event;

    /**
     * user
     *
     * @type {User}
     */
    public user: User;

    /**
     * current language
     *
     * @type string
     */
    public userLang: string;

    /**
     * if we should show sidebar
     *
     * @type {boolean}
     */
    public hideSidebar: boolean = false;

    /**
     * if we should remove sidebar
     *
     * @type {boolean}
     */
    public disableSidebar: boolean = false;

    /**
     * decide mode, preview or normal
     *
     * @type {boolean}
     */
    public isPreview: boolean = false;

    public router: Router;

    /**
     * decide mode, with or without notifications
     *
     * @type {boolean}
     */
    public notificationsVisible: boolean = false;

    /**
     * blocks feed detail to be opened multiple times
     *
     * @type boolean
     */
    public feedDetailModalOpened: boolean = false;

    /**
     * env configuration
     */
    public env = environment;

    /**
     * theme configuration, based on domain
     */
    public themeConfig = getThemeConfig();

    /**
     * constructor
     */
    constructor() {
        this.translate = ServiceLocatorService.injector.get(TranslateService);
        this.appEvents = ServiceLocatorService.injector.get(Events);
        this.overlayService = ServiceLocatorService.injector.get(OverlayService);
        this.modalController = ServiceLocatorService.injector.get(ModalController);
        this.routerExtService = ServiceLocatorService.injector.get(RouterExtService);
        this.plt = ServiceLocatorService.injector.get(PlatformService);
        this.participantService = ServiceLocatorService.injector.get(ParticipantService);
        this.eventService = ServiceLocatorService.injector.get(EventService);
        this.userService = ServiceLocatorService.injector.get(UserService);
        this.router = ServiceLocatorService.injector.get(Router);
        this.marketplaceService = ServiceLocatorService.injector.get(MarketplaceService);
        this.nav = ServiceLocatorService.injector.get(NavController);

        // debug output in development and testing environment only
        if (environment.name !== 'staging' && environment.name !== 'production') {
            // just a reminder as console output
            if (!sessionStorage.getItem('baseClassSuperHooksWarningDisplayed')) {
                console.warn('+++ IMPORTANT +++ Please ensure that you don\'t forget to call possible overridden base class lifecycle hooks in all of your components +++');
                sessionStorage.setItem('baseClassSuperHooksWarningDisplayed', 'true');
            } else {
                if (!sessionStorage.getItem('baseClassSuperHooksWarningTimeout')) {
                    const timeoutId = setTimeout(() => {
                        sessionStorage.removeItem('baseClassSuperHooksWarningDisplayed');
                        sessionStorage.removeItem('baseClassSuperHooksWarningTimeout');
                    }, 10000);
                    sessionStorage.setItem('baseClassSuperHooksWarningTimeout', timeoutId.toString());
                }
            }
        }

        this.appEvents.subscribe('header:changed', (notifications) => {
            this.notificationsVisible = notifications > 0;
            // if (this.pageContent) {
            //   this.pageContent.resize();
            // }
        });
        this.plt.hideSidebar = this.hideSidebar;

        this.userLang = this.translate.currentLang;

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.userLang = event.lang;
        });
    }

    // TODO  DEPRECEATED - replaced by angular call cycle...
    /**
     * after view entered
     *
     * @return void
     */
    ionViewDidEnter() {
        // if (this.pageContent) {
        //   console.info('resize');
        //   this.pageContent.resize();
        //
        //   this.pageContent.ionScrollEnd.subscribe((data) => {
        //     this.pageContent.resize();
        //   });
        // }
    }

    /**
     * after view leave
     *
     * @return void
     */
    ionViewDidLeave() {

    }

    /**
     * show loading overlay
     *
     * @return void
     */
    protected showLoading(content: string = 'LOADING') {
        this.overlayService.showLoading(content);
    }

    /**
     * hide loading overlay
     *
     * @return void
     */
    protected hideLoading() {
        this.overlayService.hideLoading();
    }

    /**
     * show success
     *
     * @param text
     */
    protected showSuccess(text) {
        this.overlayService.showSuccess(text);
    }

    /**
     * show error
     *
     * @param text
     */
    protected showError(text = '', cssClass = '') {
        this.overlayService.showError(text, false, cssClass);
    }

    /**
     * show error
     *
     * @param text
     */
    protected showWarning(text, cssClass = '') {
        this.overlayService.showWarning(text, cssClass);
    }

    /**
     * show info
     *
     * @param text
     */
    protected showInfo(text) {
        this.overlayService.showInfo(text);
    }

    /**
     * Show confirm
     *
     * @param title
     * @param message
     * @param callback
     */
    public showConfirm(title, message, callback, callback2?, ok?, cancel?) {
        this.overlayService.showConfirm(title, message, callback, callback2, ok, cancel);
    }

    /**
     * go to participants detail page
     *
     * @param participant
     *
     * @return void
     */
    public async showAttendeeDetail(attendee: Participant, force: boolean = false, appointmentRequest = false, attendeeId = null, direct: boolean = false) {
        if (!attendeeId && (this.isPreview || (!this.user.canProfileDetails(attendee) && !force) || this.feedDetailModalOpened)) {
            return false;
        }

        this.feedDetailModalOpened = true;
        this.plt.attendeeDetailOpened = true;

        // direct opening
        if (direct) {
            this.routerExtService.softNavigate();
        } else {
            // update url
            let link = this.routerExtService.currentUrl.split("?")[0].split("#")[0] + '?profile_id=' + (attendee ? attendee.id.toString() : attendeeId);
            // add event id also
            link += '&event_id=' + this.event.id.toString();
            this.routerExtService.softNavigate(link);
        }


        const modal = await this.modalController.create({
            component: AttendeeDetailComponent,
            cssClass: 'attendee-modal',
            componentProps: {
                attendeeId: attendeeId,
                attendee: attendee,
                appointmentRequest: appointmentRequest
            }
        });

        modal.onWillDismiss().then((data) => {
            this.feedDetailModalOpened = false;
            this.plt.attendeeDetailOpened = false;

            if (data.data?.action == 'close' || data.role == 'backdrop') {
                if (direct) {
                    this.routerExtService.softBack();
                } else {
                    let link = this.plt.defaultLink + this.routerExtService.currentUrl.split("?")[0].split("#")[0].replace('/tabs', '');
                    if (this.plt.tabMenu && !this.routerExtService.currentUrl.includes('/tabs')) {
                        this.router.navigateByUrl(link);
                    } else {
                        this.routerExtService.softNavigate(link);
                    }
                }
            } else {
                // do nothing for back navigation from browser..
                // just close modal and update history
                this.routerExtService.popHistory();
            }
        });

        return await modal.present();
    }

    /**
     * load marketplace post detail and open modal if possible
     *
     * @return modal
     */
    public async showMarketplacePostDetail(postId: number, marketplaceId: number, direct: boolean = false, visibleComments: boolean = false) {
        if (!this.feedDetailModalOpened) {
            this.feedDetailModalOpened = true;
            this.marketplaceService.getPost(+marketplaceId, +postId).subscribe(
                (marketplacePost: MarketplacePost) => {
                    this.openMarketplacePostDetail(new MarketplacePost(marketplacePost), direct, visibleComments);
                },
                (error) => {
                    // show error
                    this.overlayService.showError(error.error.message);
                    this.feedDetailModalOpened = false;
                    // go home page
                    this.nav.navigateRoot([this.plt.defaultLink + '/home']);
                });
        }
    }

    /**
     * open marketplace post detail with comments in new modal
     *
     * @return modal
     */
    public async openMarketplacePostDetail(post: MarketplacePost, direct: boolean = false, visibleComments: boolean = false) {

        this.feedDetailModalOpened = true;
        this.plt.marketplacePostOpened = true;

        // direct opening
        if (direct) {
            this.routerExtService.softNavigate();
        } else {
            // update url
            let link = this.routerExtService.currentUrl.split("?")[0].split("#")[0] + '?post_id=' + post.id.toString();
            // add event id also
            link += '&marketplace_id=' + post.marketplace_id.toString();
            link += '&event_id=' + this.event.id.toString();

            if (visibleComments) {
                link += '&showComments=1';
            }

            this.routerExtService.softNavigate(link);
        }

        post.commentsVisible = true;

        let marketplace = new Marketplace(post.marketplace);
        let marketplacePost = new MarketplacePost(post);
        let categoryName = '';

        if (!visibleComments) {
            // get translations for category label
            if (marketplace && marketplace.categories) {
                marketplace.categories.forEach((category) => {
                    if (marketplacePost && category.id == marketplacePost.marketplace_category_id) {
                        categoryName = category.translate(this.userLang, this.translate.defaultLang).name;
                    }
                });
            }
        }

        let detail = await this.modalController.create({
            component: MarketplaceAdDetailComponent,
            cssClass: 'marketplace-details-modal',
            componentProps: {
                isPreview: true,
                marketplace: marketplace,
                marketplacePost: marketplacePost,
                categoryName: categoryName
            }
        });

        if (visibleComments) {
            detail = await this.modalController.create({
                component: MarketplaceDetailComponent,
                cssClass: 'marketplace-detail',
                componentProps: {
                    event: this.event,
                    user: this.user,
                    marketplace: marketplace,
                    marketplacePost: marketplacePost,
                    modal: true
                }
            });
        }

        detail.onWillDismiss().then((data) => {
            // unset modal status
            this.feedDetailModalOpened = false;
            this.plt.marketplacePostOpened = false;

            if (data.data?.action == 'close' || data.role == 'backdrop') {
                if (direct) {
                    this.routerExtService.softBack();
                } else {
                    let link = this.plt.defaultLink + this.routerExtService.currentUrl.split("?")[0].split('#')[0].replace('/tabs', '');
                    if (this.plt.tabMenu && !this.routerExtService.currentUrl.includes('/tabs')) {
                        this.router.navigateByUrl(link);
                    } else {
                        this.routerExtService.softNavigate(link);
                    }
                }
            } else {
                // do nothing for back navigation from browser..
                // just close modal and update history
                this.routerExtService.popHistory();
            }
        });

        return await detail.present();
    }

    /**
     * show modal with live voting info
     *
     * @return modal
     */
    public async showLiveVoting(votingId, direct: boolean = false) {

        this.feedDetailModalOpened = true;
        this.plt.votingOpened = true;

        // direct opening
        if (direct) {
            this.routerExtService.softNavigate();
        } else {
            // update url
            let link = this.routerExtService.currentUrl.split("?")[0].split("#")[0] + '?voting_id=' + votingId.toString();
            // add event id also
            link += '&event_id=' + this.event.id.toString();
            this.routerExtService.softNavigate(link);
        }

        const modal = await this.modalController.create({
            component: LiveVotingComponent,
            cssClass: 'attendee-modal',
            componentProps: {
                // showPostpone: notification.data.type == 'live-voting-chat-group-direct-open',
                votingId: votingId
            }
        });

        modal.onWillDismiss().then((data) => {
            this.feedDetailModalOpened = false;
            this.plt.votingOpened = false;

            if (data.data?.action == 'close' || data.data?.action == 'postpone' || data.role == 'backdrop') {
                if (direct) {
                    this.routerExtService.softBack();
                } else {
                    let link = this.plt.defaultLink + this.routerExtService.currentUrl.split("?")[0].split("#")[0].split("#")[0].replace('/tabs', '');
                    if (this.plt.tabMenu && !this.routerExtService.currentUrl.includes('/tabs')) {
                        this.router.navigateByUrl(link);
                    } else {
                        this.routerExtService.softNavigate(link);
                    }
                }
            } else {
                // do nothing for back navigation from browser..
                // just close modal and update history
                this.routerExtService.popHistory();
            }
        });

        return await modal.present();
    }

    /**
     * show modal with live voting info
     *
     * @return modal
     */
    public async showVideo(videoLink: string, direct: boolean = false) {

        this.feedDetailModalOpened = true;
        this.plt.videoOpened = true;

        // direct opening
        if (direct) {
            this.routerExtService.softNavigate();
        } else {
            // update url
            let link = this.routerExtService.currentUrl.split("?")[0] + '?video_link=' + encodeURIComponent(videoLink);
            // add event id also
            link += '&event_id=' + this.event.id.toString();
            this.routerExtService.softNavigate(link);
        }

        const modal = await this.modalController.create({
            component: VideoDetailComponent,
            cssClass: 'video-modal' + (this.plt.is('ios') ? ' modal-ios' : ''),
            componentProps: {
                videoLink: videoLink
            }
        });

        modal.onWillDismiss().then((data) => {
            this.feedDetailModalOpened = false;
            this.plt.videoOpened = false;

            if (data.data?.action == 'close' || data.role == 'backdrop') {
                if (direct) {
                    this.routerExtService.softBack();
                } else {
                    let link = this.plt.defaultLink + this.routerExtService.currentUrl.split("?")[0].split("#")[0].replace('/tabs', '');
                    if (this.plt.tabMenu && !this.routerExtService.currentUrl.includes('/tabs')) {
                        this.router.navigateByUrl(link);
                    } else {
                        this.routerExtService.softNavigate(link);
                    }
                }
            } else {
                // do nothing for back navigation from browser..
                // just close modal and update history
                this.routerExtService.popHistory();
            }
        });

        return await modal.present();
    }

    /**
     * switch language
     *
     * @param {string} lang
     */
    public switchLanguage(lang: string) {
        this.translate.use(lang).subscribe();
        this.userLang = lang;
    }

    /**
     * rediret to chat with selected attendee
     *
     */
    public goToConversation(attendeeId: number) {
        // check if participant can start conversation
        this.participantService.canStartConversation(attendeeId).subscribe(
            (result) => {
                // participant can join conversation
                if (result) {
                    this.router.navigate([this.plt.defaultLink + '/messages/direct', attendeeId]);
                }
                // participant can not join conversation
                else {
                    this.showError(this.translate.instant('CONVERSATION_LIMIT_REACHED'));
                }
            }
        );
    }
}
