import { APP_ID, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Platform } from '@ionic/angular';

// ionic
import { Events } from '../helpers/events';

// rxjs
import { take } from 'rxjs/operators';
import { ReplaySubject } from "rxjs";
import { Observable } from 'rxjs';

// other
import * as Sentry from '@sentry/browser';
import * as moment from "moment";

// services
import { OverlayService } from "./overlay.service";


// models
import { ApiResponse } from "../models/api-response";
import { User } from "../models/user";
import { Event } from "../models/event";
import { Participant } from "../models/participant";
import { Blacklist } from "../models/blacklist";

// config
import { environment } from "../../environments/environment";
import { theme } from "../../environments/theme";
import { AuthenticationService } from './authentication.service';
import { StorageService } from './storage.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PlatformService } from './platform.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    /**
     * current user
     *
     * @type {ReplaySubject}
     */
    private currentUser: any = new ReplaySubject<User>(1);

    // NOTE[jg] - or BehaviorSubject??


    /**
      * constructor
      *
      * @param jwtHttp
      * @param events
      * @param eventProvider
      * @param overlayService
      */
    constructor(
        @Inject(APP_ID) private appId: string,
        private jwtHelper: JwtHelperService,
        private http: HttpClient,
        private storage: StorageService,
        private router: Router,
        public events: Events,
        //public eventProvider: EventProvider,
        public overlayService: OverlayService,
        public platform: Platform
    ) {
        this.events.subscribe('user:current', ((data) => this.loadCurrentUser(true, null, data && data.eventId ? data.eventId : null)));
        this.events.subscribe('user:current:participant', ((data) => this.loadCurrentUser(true, data && !!data.redirectLink ? data.redirectLink : null, data && data.eventId ? data.eventId : null)));
        // this.events.subscribe('loggedin', (() => this.loadCurrentUser()));
        this.events.subscribe('loggedout', (() => this.clearCurrentUser()));
    }

    /**
     * get current user
     *
     * @return {Observable<User>}
     */
    public getCurrentUser(): Observable<User> {
        return this.currentUser.asObservable();
    }

    /**
     * set current user
     *
     * @param user
     */
    public setCurrentUser(user: User) {
        if (user.selected_participant_id && user.selected_participant.event) {
            user.selected_participant.event = new Event(user.selected_participant.event);
            //this.eventProvider.setCurrentEvent(new Event(user.selected_participant.event, user));
        }
        this.currentUser.next(user);

        if (user.selected_participant) {
            this.events.publish('custom:design', {
                setting: user.selected_participant.event.setting,
            });

            let showWizard = user.selected_participant.event.show_wizard;

            if (user.selected_participant.event.use_groups) {
                let group = user.selected_participant.event.groupSettings.filter(group => group.id == user.selected_participant.group_id);
                if (group[0] && group[0].show_wizard) {
                    showWizard = true;
                } else {
                    showWizard = false;
                }
            }

            let step = 0;
            // show wizard only after accepting TOC
            if (!user.toc_needed && !user.toc_refresh && user.selected_participant instanceof Participant) {

                // check how many steps is available in event
                let wizardSteps = 3;;
                if (!user.selected_participant.event.matching_status) {
                    wizardSteps = 2;
                }

                // check if any item is required, or wizard is enabled and user has not filled it yet
                if ((step = user.selected_participant.showWizard())
                    || (showWizard && (!user.selected_participant.wizard_status || user.selected_participant.wizard_status < wizardSteps))) {

                    // console.info('Step: ' + step);
                    // console.info('showWizard: ' + showWizard);

                    let wizardSkip = false;

                    // check wizard skip posponing, data stored in session
                    let key = 'skip_wizard_' + user.selected_participant.event_id
                    const itemStr = sessionStorage.getItem(key)
                    // if the item doesn't exist, return null
                    if (!!itemStr) {
                        const item = JSON.parse(itemStr)
                        const now = new Date()
                        // compare the expiry time of the item with the current time
                        if (now.getTime() > item.expiry) {
                            // If the item is expired, delete the item from storage
                            // and do nothing
                            localStorage.removeItem(key)
                        } else {
                            // dont show wizard as user ask to postpone it..
                            wizardSkip = true;
                        }
                    }

                    if (!wizardSkip) {
                        setTimeout(() => {
                            this.events.publish('custom:profile', {
                                profile_setting: user.selected_participant.event_setting.profile_setting,
                                step: step
                            });
                        }, 500);
                    }
                }
            }
        }

        // set participant ID for error loging
        if (environment.sentry.enabled) {
            Sentry.configureScope(scope => {
                //scope.setTag('user_mode', 'admin');
                scope.setUser({ id: "" + user.selected_participant_id });
            });
        }
    }

    /**
     * reload current user
     *
     * @return void
     */
    public loadCurrentUser(withParticipant: boolean = true, redirectLink = null, eventId = null) {

        let headers = new HttpHeaders();
        let params = new HttpParams();
        params = params.append("withParticipant", withParticipant ? "true" : "false");
        if (eventId) {
            params = params.append("event_id", eventId);
        }

        this.http.get(environment.api + '/user/current', { headers: headers, params: params })
            .subscribe((user: User) => {

                // update token in case that it is refreshed, token includes selected profile...
                if (user.token) {
                    this.updateToken(user.token);
                }

                this.setCurrentUser(new User(user));
                if (redirectLink) {
                    this.router.navigate([redirectLink], { replaceUrl: true });
                }
            }, error => {
                if (!error.error || !error.status) {
                    //this.overlayService.showConnectionProblems(error);
                    this.events.publish('logout');
                }
            });
    }

    /**
     * clear current User
     *
     * @return void
     */
    public clearCurrentUser() {
        //this.currentUser = new ReplaySubject<User>(1);
        this.setCurrentUser(new User());
    }

    /**
     * update user password
     *
     * @param user
     *
     * @return {Observable<any>}
     */
    public updatePassword(user: User): Observable<any> {
        return this.http.put(environment.api + '/user/password/' + user.id, user);
    }

    /**
     * destroy user by id
     *
     * @param id
     *
     * @return {Observable<any>}
     */
    public delete(id: number): Observable<any> {
        return this.http.delete(environment.api + '/user/' + id);
    }

    /**
     * unset users linkedin     *
     *
     * @return {Observable<any>}
     */
    public unsetLinkedIn(): Observable<any> {
        return this.http.post(environment.api + '/user/unset-linkedin', {});
    }

    /**
     * set selected user event
     *
     * @param userId
     * @param eventId
     *
     * @return {Observable<any>}
     */
    public setSelectedEvent(userId: number, eventId: number): Observable<any> {
        const params = { event_id: eventId };

        return this.http.put(environment.api + '/user/selected-event/' + userId, JSON.stringify(params));
    }

    /**
     * use event key
     *
     * @param userId
     * @param eventKey
     *
     * @return {Observable<any>}
     */
    public useEventKey(eventKey: string): Observable<any> {
        const params = {
            event_key: eventKey
        };

        return this.http.post(environment.api + '/user/event-key', JSON.stringify(params));
    }

    /**
     * update device
     *
     * @param data
     *
     * @returns {Observable<any>}
     */
    public updateDevice(data: any): Observable<any> {
        return this.http.post(environment.api + '/user/update-device', JSON.stringify({
            device: data,
            app: theme.name,
        }));
    }

    /**
     * update device
     *
     * @param data
     *
     */
    public checkLiveStatus(liveStatus): void {
        if (liveStatus['user_updated_at']) {
            this.getCurrentUser().pipe(
                take(1)
            ).subscribe((user) => {
                if (user.id) {
                    let userUpdateAt = moment.utc(liveStatus.user_updated_at).toDate();
                    if (user && user.date_updated_at < userUpdateAt) {
                        this.loadCurrentUser()
                    }
                }
            })

        }
    }

    /**
     * set TOC
     *
     * @param any
     *
     * @return {Observable<any>}
     */
    public setTOC(model: any): Observable<any> {
        return this.http.post(environment.api + '/user/toc', model);
    }

    /**
 * get live data for user
 * @return void
 */
    public getLiveState(id: number, track: boolean = false, disabledMessaging: boolean = false): Observable<any> {

        let params = new HttpParams();

        if (track) {
            params = params.append('track', '1');
        }

        if (disabledMessaging) {
            params = params.append('disabledMessaging', '1');
        }

        return this.http.get(environment.api + '/user/' + id + '/live-state', { params: params });
    }

    /**
     * attach participant
     *
     * @param string
     *
     * @return {Observable<any>}
     */
    public attachParticipant(invitation_token: string): Observable<any> {
        return this.http.post(environment.api + '/user/attach-participant', { 'invitation_token': invitation_token });
    }

    /**
     * set security cookies for cloudfront
     *
     * @param string
     *
     * @return {Observable<any>}
     */
    public setCloudfrontCookies(): Observable<any> {

        let timezone = '&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone;

        timezone += '&timezone_offset=' + new Date().getTimezoneOffset();

        let url;

        // set cookies for assets cloudfront distribution
        if (!environment.cookieForAssets) {
            url = environment.api + '/user/cookies/refresh?ngsw-bypass=true' + timezone;
        } else {
            if (environment.sameSiteAssets && !this.platform.is('cordova')) {
                // NOTE[jg] sync this with plt.apiPrefix
                url = location.origin + '/api/v2/user/cookies/refresh?ngsw-bypass=true&sameSiteAssets=true' + timezone;
            } else {
                url = environment.assets + '/api/v2/user/cookies/refresh?ngsw-bypass=true' + timezone;
            }
        }

        // // hack for setting signed cookies for assets domains
        // var iframe = document.createElement('iframe');
        // iframe.src = url + '&token=' + localStorage.getItem('token');
        // iframe.width = '0px';
        // iframe.height = '0px';
        // iframe.style.display ='none';
        // document.body.appendChild(iframe);

        return this.http.get(url)
    }

    /**
     * unset security cookies for cloudfront
     *
     * @param string
     *
     * @return {Observable<any>}
     */
    public unsetCloudfrontCookies(): Observable<any> {

        // const headerDict = {
        //     'Authorization': 'Bearer xxx',
        // };

        // const requestOptions = new RequestOptions();
        // requestOptions.headers = new Headers(headerDict);
        // requestOptions.withCredentials = true;

        let headers = new HttpHeaders();
        let params = new HttpParams();

        headers = headers.append("Authorization", 'Bearer xxx');

        // unset cookies
        if (!environment.cookieForAssets) {
            return this.http.get(environment.api + '/user/cookies/remove?ngsw-bypass=true', { headers: headers, params: params });
        } else {
            if (environment.sameSiteAssets && !this.platform.is('cordova')) {
                // NOTE[jg] sync this with plt.apiPrefix
                return this.http.get(location.origin + '/api/v2/user/cookies/remove?ngsw-bypass=true', { headers: headers, params: params });
            } else {
                return this.http.get(environment.assets + '/api/v2/user/cookies/remove?ngsw-bypass=true', { headers: headers, params: params });
            }
        }
    }

    /**
     * update users blacklist
     *
     * @param {Blacklist} blacklist
     *
     * @return {Observable<any>}
     */
    public updateBlacklist(blacklist: Blacklist): Observable<any> {
        return this.http.post(environment.api + '/user/blacklist', blacklist);
    }

    /**
     * check user video upload status
     *
     * @param {string} id
     *
     * @return {Observable<any>}
     */
    public checkVideoUpload(id: string): Observable<any> {
        return this.http.get(environment.api + '/user/check-video-upload/' + id);
    }

    /**
     * update user token
     *
     * @param {string} token
     *
     */
    public updateToken(token: string) {
        localStorage.setItem('token', token);
        localStorage.setItem('refresh_token', token);

        // always store token permanently
        this.storage.set('token', token);

        const channel = new BroadcastChannel('app-data');
        let data = this.jwtHelper.decodeToken(token)
        data['broadcastSender'] = this.appId;
        channel.postMessage(data);
    }
}
