import { Injectable } from '@angular/core';

// other services
import { HttpClient } from '@angular/common/http';

// rxjs
import { Observable } from 'rxjs';

// models
import { Marketplace } from "../models/marketplace";
import { MarketplacePost } from "../models/marketplace-post";
import { MarketplaceKeyword } from "../models/marketplace-keyword";
import { Participant } from "../models/participant";
import { MarketplaceHeadline } from "../models/marketplace-headline";

// configuration
import { environment } from "../../environments/environment";

import { AutoCompleteService } from './auto-complete.service';

@Injectable({
    providedIn: 'root'
})
export class MarketplaceService implements AutoCompleteService {

    /**
     * current marketplace id
     *
     * @type {number}
     */
    public marketplaceId;

    /**
     * constructor
     *
     * @param jwtHttp
     */
    constructor(private http: HttpClient) { }

    /**
     * get all posts for the given marketplace
     *
     * @param {number} marketplaceId
     * @param limit
     * @param offset
     *
     * @return {Observable<any>}
     */
    public getPosts(marketplaceId: number,
        limit?: number,
        offset?: number,
        categoryId?: number,
        filterKeywords?: MarketplaceKeyword[],
        search?: string,
        filterByKeywords?: MarketplaceKeyword[],
        onlyKeywords: boolean = false
    ): Observable<any> {
        let params = {
            limit: limit || 0,
            offset: offset || 0,
            filterKeywords: filterKeywords || null,
            filterByKeywords: filterByKeywords || [],
            categoryId: categoryId || false,
            search: search || '',
            onlyKeywords: onlyKeywords
        };

        return this.http.post(environment.api + '/marketplace/' + marketplaceId + '/posts/list', params);
    }

    /**
     * create post
     *
     * @param post
     *
     * @return {Observable<any>}
     */
    public createPost(post: MarketplacePost): Observable<any> {
        return this.http.post(environment.api + '/marketplace/' + post.marketplace_id + '/posts', post);
    }

    /**
     * update post
     *
     * @param post
     *
     * @return {Observable<any>}
     */
    public updatePost(post: MarketplacePost): Observable<any> {
        return this.http.put(environment.api + '/marketplace/' + post.marketplace_id + '/posts/' + post.id, post);
    }

    /**
     * update post
     *
     * @param post
     *
     * @return {Observable<any>}
     */
    public updatePostDeails(post: MarketplacePost): Observable<any> {
        return this.http.put(environment.api + '/marketplace/' + post.marketplace_id + '/posts/' + post.id + '/details', post);
    }

    /**
     * get post by id
     *
     * @param id - post id
     * @param marketplaceId
     *
     * @return {Observable<any>}
     */
    public getPost(marketplaceId: number, id: number): Observable<any> {

        let url = environment.api + '/marketplace/' + marketplaceId + '/posts/' + id;
        if (sessionStorage.getItem('preview_token')) {
            url += '?token=' + sessionStorage.getItem('preview_token');
        }

        return this.http.get(url);
    }

    /**
     * visit post by id
     *
     * @param id - post id
     * @param marketplaceId
     *
     * @return {Observable<any>}
     */
    public visitPost(marketplaceId: number, id: number): Observable<any> {
        return this.http.get(environment.api + '/marketplace/' + marketplaceId + '/posts/' + id + '/visit');
    }

    /**
     * destroy post by id
     *
     * @param {number} marketplaceId
     * @param {number} id
     *
     * @return {Observable<any>}
     */
    public deletePost(marketplaceId: number, id: number): Observable<any> {
        return this.http.delete(environment.api + '/marketplace/' + marketplaceId + '/posts/' + id);
    }

    /**
     * toggle post like
     *
     * @param post
     *
     * @return {Observable<any>}
     */
    public togglePostLike(post: MarketplacePost): Observable<any> {
        return this.http.post(environment.api + '/marketplace/' + post.marketplace_id + '/posts/' + post.id + '/toggleLike', {});
    }
    /**
     * post comment
     *
     * @param post
     *
     * @return {Observable<any>}
     */
    public postComment(post: MarketplacePost): Observable<any> {
        // edit
        if (post.editComment.id) {
            return this.http.put(environment.api + '/marketplace/' + post.marketplace_id + '/posts/' + post.id + '/comments/' + post.editComment.id, post.editComment);
        }
        // create
        else {
            return this.http.post(environment.api + '/marketplace/' + post.marketplace_id + '/posts/' + post.id + '/comments', post.editComment);
        }
    }

    /**
     * delete comment
     *
     * @param post
     * @param id - comment id
     *
     * @return {Observable<any>}
     */
    public deleteComment(post: MarketplacePost, id: number): Observable<any> {
        // edit
        return this.http.delete(environment.api + '/marketplace/' + post.marketplace_id + '/posts/' + post.id + '/comments/' + id);
    }

    /**
     * store users marketplace keywords preference
     *
     * @param marketplaceId
     * @param keywords
     *
     * @return {Observable<any>}
     */
    public storeUserKeywords(marketplaceId: number, keywords = []): Observable<any> {
        return this.http.post(environment.api + '/marketplace/' + marketplaceId + '/userKeywords', keywords);
    }

    /**
     * store users marketplace keywords preference
     *
     * @param marketplaceId
     * @param keywords
     *
     * @return {Observable<any>}
     */
    public getUserKeywords(marketplaceId: number): Observable<any> {
        return this.http.get(environment.api + '/marketplace/' + marketplaceId + '/userKeywords');
    }

    /**
     * Suggest keyword
     *
     * @param participantId
     * @param marketplaceId
     * @param keyword
     * @returns {Observable<ApiResponse>}
     */
    public suggestKeyword(participantId: number, marketplaceId: number, keyword: MarketplaceKeyword): Observable<any> {
        return this.http.post(environment.api + '/marketplace/' + marketplaceId + '/suggestKeyword/' + participantId, {
            keyword: keyword
        });
    }

    /**
     * destroy keyword by id
     *
     * @param participantId
     * @param {number} id ID of keyword
     *
     * @return {Observable<any>}
     */
    public deleteKeyword(marketplaceId: number, id: number): Observable<any> {
        return this.http.delete(environment.api + '/marketplace/' + marketplaceId + '/keywords/' + id);
    }

    /**
     * Buy marketplace ad
     *
     * @param post
     * @param amount
     * @returns {Observable<ApiResponse>}
     */
    public purchase(post: MarketplacePost, amount: number = 1, deliveryAddress: { street: string, streetnr: string, zip: string, city: string, country: string } = null): Observable<any> {
        return this.http.post(environment.api + '/marketplace/' + post.marketplace_id + '/posts/' + post.id + '/purchase', {
            amount: amount,
            deliveryAddress: deliveryAddress
        });
    }

    /**
     * Get marketplace orders
     *
     * @param post
     * @returns {Observable<ApiResponse>}
     */
    public orders(post: MarketplacePost): Observable<any> {
        return this.http.get(environment.api + '/marketplace/' + post.marketplace_id + '/posts/' + post.id + '/orders');
    }

    /**
     * Get a list of participants for a marketplace by search attribute
     *
     * @param keyword
     * @returns {Observable<any[]>}
     */
    getResults(keyword: string): Observable<any[]> {

        return this.getParticipantList(
            this.marketplaceId,
            {
                limit: 150,
                search: keyword
            }
        );
    }

    /**
     * Get a list of participants for joined events
     *
     * @param id
     * @param params
     * @returns {Observable<any>}
     */
    public getParticipantList(id: number, params: any = {}): Observable<any> {
        let postParams = {};
        postParams['limit'] = params.length;
        postParams['offset'] = params.start;
        postParams['search'] = params.search;

        if (!postParams['search'] && !!params.search.value) {
            postParams['search'] = params.search.value;
        }

        return this.http.post(environment.api + '/marketplace/' + id + '/participants', postParams);
    }

    /**
     * remove my contact from post
     *
     * @param {number} marketplaceId
     * @param {number} id
     *
     * @return {Observable<any>}
     */
    public deleteMyContact(headline: MarketplaceHeadline, participant: Participant): Observable<any> {
        return this.http.delete(environment.api + '/marketplace/posts/removeMyContact/' + headline.id + '/' + participant.id);
    }
}
