import { Injectable, NgZone } from '@angular/core';
import { Router, RouterEvent, NavigationEnd, NavigationStart, Scroll } from '@angular/router';
import { Title } from "@angular/platform-browser";
import { Events } from '../helpers/events';

import { PlatformService } from "./platform.service";

import { log } from '../helpers/log';
import { MenuController } from '@ionic/angular';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { MatomoTracker } from 'ngx-matomo-client';

@Injectable({
    providedIn: 'root'
})
export class RouterExtService {

    private previousUrl: string = '';
    public currentUrl: string = '';
    private history: string[] = [];

    public backNavigation: boolean = false;

    public pageTitles = {
        '/login': 'LOGIN_PARTICIPANT',
        '/lost-password': 'LOST_PASSWORD_TITLE',
        '/register': 'REGISTRATION_TITLE',
        '/setting': 'USER_SETTING_TITLE',
        '/keywords/matching': 'KEYWORDS_PAGE_TITLE',
        '/edit-profile': 'PROFILE_EDIT_TITLE',
        '/home': 'LEFT_MENU_DASHBOARD',
        '/home/marketplace/list': 'DASHBOARD_SUBMENU_MARKETPLACE',
        '/home/attendees/list': 'LEFT_MENU_ATTENDEES',
        '/home/detail/event': 'LEFT_MENU_EVENT_INFO',
        '/appointments': 'LEFT_MENU_APPOINTMENTS',
        '/ask-the-speaker': 'ASK_THE_SPEAKER_HEADER',
        '/messages': 'LEFT_MENU_MESSAGES',
        '/credentials-wallet': 'CREDENTIAL_WALLET_PAGE_TITLE'
    };

    public trackingPageTitles = {
        '/login': 'Login',
        '/lost-password': 'Lost password',
        '/register': 'Registration',
        '/setting': 'User settings',
        '/keywords/matching': 'Keywords',
        '/edit-profile': 'Edit profile',
        '/home': 'Home',
        '/home/marketplace/list': 'Content Hub',
        '/home/attendees/list': 'Members',
        '/home/detail/event': 'Event info',
        '/appointments': 'Appointments',
        '/ask-the-speaker': 'Ask the Speaker',
        '/messages': 'Messages',
        '/credentials-wallet': 'Credential Wallet'
    };

    public originalTitle = '';

    constructor(
        private plt: PlatformService,
        private router: Router,
        private menu: MenuController,
        public ngZone: NgZone,
        private appEvents: Events,
        private titleService: Title,
        private translate: TranslateService,
        private tracker: MatomoTracker
    ) {

        this.currentUrl = this.router.url;

        this.originalTitle = this.titleService.getTitle();

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.setTitle();
        });

        router.events.subscribe(
            (event: any) => {

                // for route event event get route event
                if ((event instanceof Scroll)) {
                    event = <RouterEvent>event.routerEvent;
                }

                // using url from NavigationEnd instance, because route.snapshot was not sometimes updated...
                if (event instanceof RouterEvent && event instanceof NavigationStart) {

                    let url = event.url;

                    // set page title
                    this.setTitle(url);

                    // solution for offline mode to keep user on proper site
                    if (this.previousUrl && !this.plt.isConnected) {
                        // redirect to home allways
                        if (url != this.plt.defaultLink + '/home/detail/event') {
                            this.router.navigate([this.plt.defaultLink + '/home/detail/event']);
                        }
                        return;
                    }

                    log('info', "NavigationStart", event);

                    // decide if navigation was back or forward, based on history and type of event
                    // popstate -> initialized by browser back or forward button
                    let navigateBack = event.navigationTrigger === 'popstate'
                        && (this.history.length == 0 || url == this.previousUrl || (this.history.length > 0 && this.history[this.history.length - 1].split('#')[0] == event.url.split('#')[0]));

                    log('info', "NavigateBack", navigateBack);

                    if (!navigateBack) {

                        if (!this.backNavigation
                            && this.currentUrl != url
                            && this.currentUrl != "/"
                            && this.currentUrl != this.history[this.history.length - 1]
                        ) {
                            this.history.push(this.currentUrl);
                        }
                        this.previousUrl = this.currentUrl;
                        this.currentUrl = event.url;
                        this.backNavigation = false;

                    } else {
                        // on navigation back check if we can go

                        // if menu is opened, just close it
                        if (this.plt.tabMenu && this.plt.tabMenuOpened) {
                            this.menu.close();
                            // keep user on the same page
                            this.router.navigate([this.currentUrl]);
                        } else {
                            this.popHistory();
                        }
                        if (!this.canGoBack() && localStorage.getItem('token')) {
                            this.previousUrl = this.plt.defaultLink + '/home';
                            // don't navigate again when on home already
                            if (url != this.plt.defaultLink + '/home') {
                                this.router.navigate([this.plt.defaultLink + '/home']);
                            }
                        }
                    }

                    log('info', 'NavigationStart history', this.history);
                }
            });
    }

    public softNavigate(url?: string, replace = false) {

        // do nothing when on same url, empty url is used for modal navigation
        if (url == (window.location.pathname + window.location.search)) {
            return;
        }

        if (!url) {
            if (replace) {
                this.popHistory();
            }
            url = (this.currentUrl ? this.currentUrl.split('#')[0] : '') + '#' + this.history.length;
            this.history.push(this.currentUrl);
        }

        // solve navigation for iframes
        let iframe = <HTMLIFrameElement>document.querySelector('iframe#networking');
        if (!(window === window.parent) && iframe) {
            // do nothing
            // if (replace) {
            //     iframe.contentWindow.history.replaceState({}, '', `${url}`);
            // } else {
            //     iframe.contentWindow.history.pushState({}, '', `${url}`);
            // }
        } else {
            if (replace || this.currentUrl == url) {
                window.history.replaceState({}, '', `${url}`);
            } else {
                window.history.pushState({}, '', `${url}`);
            }
        }
        if (!this.backNavigation
            && this.currentUrl != url
            && this.currentUrl != "/"
            && this.currentUrl != this.history[this.history.length - 1]
        ) {
            this.history.push(this.currentUrl);
        }
        log('info', 'Soft navigate', this.history);
        this.previousUrl = this.currentUrl;
        this.currentUrl = url;
        this.appEvents.publish('route:changed');
    }

    public softBack(updateHistory: boolean = true) {
        let iframe = <HTMLIFrameElement>document.querySelector('iframe#networking');
        if (!(window === window.parent) && iframe) {
            // do nothing
            // iframe.contentWindow.history.back();
        } else {
            window.history.back();
        }

        if (updateHistory) {
            this.popHistory();
        }
    }

    public popHistory() {
        this.currentUrl = this.previousUrl;
        return this.previousUrl = this.history.pop();
    }

    public resetHistory() {
        // set first page
        this.previousUrl = undefined;
        this.history = [this.plt.defaultLink + '/home'];
    }

    public emptyHistory(): boolean {
        return this.previousUrl == undefined;
    }


    public getPreviousUrl() {
        return this.previousUrl;
    }

    // NOTE[jg] - will not be used, we use pure browser history
    public canGoBack(): boolean {
        if (this.history.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    // NOTE[jg] - changed, we use pure browser history
    public back() {
        if (this.history.length > 0) {
            this.backNavigation = true;
            // this.ngZone.run(() => {
            let url = this.popHistory();
            this.router.navigate([url]);
            // window.history.back();
            log('info', 'History pop', this.history);
            // });
        } else {
            // go home...
            this.router.navigate([this.plt.defaultLink + 'home']);
        }
    }

    public setTitle(url?) {

        if (!url) {
            url = location.pathname;
        }

        // remove tabs prefix to get proper page title
        url = url.replace(this.plt.defaultLink, '');

        if (this.pageTitles.hasOwnProperty(url)) {
            this.titleService.setTitle(this.translate.instant(this.pageTitles[url]));
        } else {
            this.titleService.setTitle(this.originalTitle);
        }
    }
}
