import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
// ionic
import { ModalController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { PopoverController } from '@ionic/angular';
import { Events } from '../../helpers/events';

// other libraries
import { TranslateService } from "@ngx-translate/core";

// rxjs
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";

// components
import { SuggestKeywordComponent } from "../../components/suggest-keyword/suggest-keyword.component";

// services
import { EventService } from "../../services/event.service";
import { RouterExtService } from "../../services/router-ext.service";
import { PlatformService } from "../../services/platform.service";
import { MarketplaceService } from "../../services/marketplace.service";
import { UserService } from "../../services/user.service";
import { OverlayService } from "../../services/overlay.service";

// models
import { Event } from "../../models/event";
import { User } from "../../models/user";
import { Marketplace } from "../../models/marketplace";
import { MarketplaceContainer } from "../../models/marketplace-container";
import { MarketplaceKeyword } from "../../models/marketplace-keyword";

@Component({
    selector: 'marketplace-keywords',
    templateUrl: './marketplace-keywords.component.html',
    styleUrls: ['./marketplace-keywords.component.scss'],
})
export class MarketplaceKeywordsComponent implements OnInit, OnDestroy {

    @Input() marketplace: Marketplace;

    @Input() pivots: any[] = [];

    @Output() onKeywordChanged: EventEmitter<any[]> = new EventEmitter();

    @Input() inSetting: boolean = false;

    /**
     * unsubscribe subject
     *
     * @type {Subject<void>}
     */
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    /**
     * event
     *
     * @type {Event}
     */
    public event: Event;

    /**
     * user
     *
     * @type {User}
     */
    public user: User = new User;

    /**
     * selected language
     *
     * @type string
     */
    public userLang: string;


    /**
     * show loading
     *
     * @type {boolean}
     */
    public loading: boolean = true;

    /**
     * show form in modal
     *
     * @type boolean
     */
    @Input() modal: boolean = false;

    /**
     * show collapsible boxes
     *
     * @type boolean
     */
    @Input() collapsible: boolean = true;

    /**
     * show favorite saving button
     *
     * @type boolean
     */
    @Input() favoriteSaving: boolean = false;


    /**
     * show suggest keywords links and suggested keywords
     *
     * @type boolean
     */
    @Input() allowKeywordSuggest: boolean = true;

    /**
     * Suggested keywords
     *
     * @type Keyword[]
     */
    public suggestedKeywords: MarketplaceKeyword[] = [];

    /**
     * Suggested keywords by others
     *
     * @type Keyword[]
     */
    public suggestedKeywordsByOthers: MarketplaceKeyword[] = [];

    /**
     * Existing keywords
     *
     * @type Keyword[]
     */
    public existingKeywords: MarketplaceKeyword[] = [];



    constructor(
        public plt: PlatformService,
        public appEvents: Events,
        public popoverController: PopoverController,
        public alertCtrl: AlertController,
        public eventService: EventService,
        public userService: UserService,
        public marketplaceService: MarketplaceService,
        public modalController: ModalController,
        public translate: TranslateService,
        public overlayService: OverlayService,
        public routerExtService: RouterExtService,
    ) {

        // user language
        this.userLang = this.translate.currentLang;
    }

    ngOnInit() {
        this.userService.getCurrentUser().pipe(
            takeUntil(this.ngUnsubscribe),
            debounceTime(0)
        ).subscribe((user) => {
            if (user.id) {
                this.user = user;

                this.eventService.getCurrentEvent().pipe(
                    takeUntil(this.ngUnsubscribe),
                    debounceTime(0)
                ).subscribe((event) => {

                    this.event = event;

                    this.loading = false;
                });
            } else {
                // TODO
            }
        });
    }

    /**
     * toggle keyword selection
     *
     * @param keyword
     * @returns {boolean}
     */
    public toggleKeyword(keyword: MarketplaceKeyword) {
        if (this.pivots.filter((item) => { return item.marketplace_keyword_id == keyword.id }).length) {
            this.pivots = this.pivots.filter((item) => {
                return item.marketplace_keyword_id != keyword.id;
            });
        } else {
            this.pivots.push({
                marketplace_keyword_id: keyword.id,
                keyword: keyword,
            });
        }

        this.onKeywordChanged.emit(this.pivots);
    }

    /**
     * Check, if the keyword is selected
     *
     * @param keyword
     * @returns {boolean}
     */
    public isSelected(keyword: MarketplaceKeyword) {
        if (this.pivots && this.pivots.filter((item) => { return item.marketplace_keyword_id == keyword.id }).length) {
            return true;
        }

        return false;
    }

    /**
     * Check, if the keyword is selected and container should be opened
     *
     * @returns void
     */
    public checkCollabsible() {
        if (this.favoriteSaving) {
            // check container and open those with selected keywords
            this.marketplace.containers.forEach((container, i) => {

                // order keywords first
                this.marketplace.containers[i].orderKeywordsByName(this.userLang);

                container.keywords.forEach((keyword) => {
                    if (this.isSelected(keyword)) {
                        this.marketplace.containers[i].open = true;
                    }
                });
            });
        }
    }

    /**
     * close modal window
     *
     */
    public backClicked() {
        this.modalController.dismiss({
            action: 'close'
        });
    }

    /**
     * close modal and pass selected keywords
     *
     */
    public seeResults() {
        this.modalController.dismiss({
            action: 'close',
            keywords: this.pivots
        });
    }


    /**
     * erase selected keywords and apply to parent
     *
     */
    public resetPreferences() {
        this.pivots = [];
        this.onKeywordChanged.emit(this.pivots);
    }

    /**
     * show popover with keyword input
     *
     * @param ev
     * @param container
     */
    async presentSuggestKeyword(ev: any, container) {
        this.routerExtService.softNavigate();

        const popover = await this.popoverController.create({
            component: SuggestKeywordComponent,
            event: ev,
            componentProps: { container: container },
            cssClass: 'edit-note'
            //translucent: true
        });

        popover.onWillDismiss().then((data) => {
            if (data.data) {
                this.suggestKeyword(data.data.keyword, data.data.container.id);
            }
            if (data.data && data.data.action && data.data.action == 'close') {
                this.routerExtService.softBack();
            }
        });

        return await popover.present();
    }

    /**
     * Add keyword (only if it doesn't exists yet)
     *
     * @param value
     * @param containerId
     */
    public suggestKeyword(value: string, containerId: number) {
        let lang = this.userLang;

        if (this.event.available_locales.indexOf(this.userLang) === -1) {
            lang = this.event.default_locale;
        }

        let container = new MarketplaceContainer();
        for (let i = 0; i < this.marketplace.containers.length; i++) {
            if (this.marketplace.containers[i].id === +containerId) {
                container = this.marketplace.containers[i];
            }
        }
        let duplicatedKeyword = false;
        if (value.trim() !== '') {
            // check, if the keyword already exists in the suggestions of others - alert
            for (let k = 0; k < this.suggestedKeywordsByOthers.length; k++) {
                if (this.suggestedKeywordsByOthers[k].translateOrNew(lang).name
                    && this.suggestedKeywordsByOthers[k].translateOrNew(lang).name.toLowerCase() === value.trim().toLowerCase()
                    && this.suggestedKeywordsByOthers[k].marketplace_container_id === container.id) {
                    duplicatedKeyword = true;
                    this.overlayService.showError(this.translate.instant('KEYWORD_SUGGESTION_ALREADY_EXISTS_BY_OTHERS', { name: value.trim() }));
                    break;
                }
            }
            // check, if the keyword already exists in own suggestions - do nothing
            for (let k = 0; k < this.suggestedKeywords.length; k++) {
                if (this.suggestedKeywords[k].translateOrNew(lang).name
                    && this.suggestedKeywords[k].translateOrNew(lang).name.toLowerCase() === value.trim().toLowerCase()
                    && this.suggestedKeywords[k].marketplace_container_id === container.id) {
                    duplicatedKeyword = true;
                    // this.onChangeLoading.emit(false);
                    // this.onChangeDataLoading.emit(false);
                    this.overlayService.showError(this.translate.instant('KEYWORD_SUGGESTION_ALREADY_EXISTS_BY_USER', { name: value.trim() }));
                }
            }
            // check, if the keyword already exists - alert
            for (let k = 0; k < this.existingKeywords.length; k++) {
                if (this.existingKeywords[k].translateOrNew(lang).name
                    && this.existingKeywords[k].translateOrNew(lang).name.toLowerCase() === value.trim().toLowerCase()
                    && this.existingKeywords[k].marketplace_container_id === container.id) {
                    duplicatedKeyword = true;
                    // this.onChangeLoading.emit(false);
                    // this.onChangeDataLoading.emit(false);
                    this.overlayService.showError(this.translate.instant('KEYWORD_ALREADY_EXISTS', { name: value.trim() }));
                    break;
                }
            }
            // insert kyword suggestion if there is no duplicate keyword
            if (!duplicatedKeyword) {
                const keyword = new MarketplaceKeyword;
                keyword.marketplace_container_id = container.id;
                keyword.containerName = container.translate(lang, this.event.default_locale).name;
                this.event.available_locales.forEach((l) => {
                    keyword.translateOrNew(l).name = value.trim();
                });

                this.marketplaceService.suggestKeyword(this.user.selected_participant.id, this.marketplace.id, keyword).subscribe(
                    (success) => {
                        // update keyword with connection to other objects and id
                        keyword.id = success.id;
                        keyword.participant_id = this.user.selected_participant.id;
                        keyword.name = keyword.translate(lang, this.event.default_locale).name;

                        // insert keyword into proper list of keywords
                        for (let c = 0; c < this.marketplace.containers.length; c++) {
                            if (this.marketplace.containers[c].id == keyword.marketplace_container_id) {
                                this.marketplace.containers[c].keywords.push(keyword);
                                this.marketplace.containers[c].orderKeywordsByName(this.userLang);
                                break;
                            }
                        };

                        // put keyword into my suggested keywords
                        this.suggestedKeywords.push(keyword);

                        // select keyword
                        this.toggleKeyword(keyword);

                        this.overlayService.showSuccess(success.message);
                    },
                    (error) => {
                        this.suggestedKeywords = this.suggestedKeywords.filter(kw => {
                            return kw.translateOrNew(lang).name && kw.translateOrNew(lang).name.toLowerCase() !== value.trim().toLowerCase();
                        });
                        // catch keyword max length errors
                        if (!!error.error.fields) {
                            for (const i in error.error.fields) {
                                if (error.error.fields.hasOwnProperty(i)) {
                                    if (i.indexOf('keyword') !== -1) {
                                        this.overlayService.showError(error.error.fields[i]);
                                        return;
                                    }
                                }
                            }
                        }
                        // this.onChangeLoading.emit(false);
                        // this.onChangeDataLoading.emit(false);
                        this.overlayService.showError(error.error.message);
                    });
            }
        }
    }

    /**
     * Delete keyword
     *
     * @param keyword
     */
    public deleteSuggestedKeyword(keyword: MarketplaceKeyword) {
        // this.onChangeLoading.emit(false);
        this.deleteKeywordFromServer(keyword);
    }

    public async openDeleteSuggest(keyword: MarketplaceKeyword) {
        let alert = await this.alertCtrl.create({
            cssClass: 'confirm',
            header: this.translate.instant('KEYWORD_DELETE'),
            message: this.translate.instant('KEYWORD_DELETE_HINT', { name: keyword.translate(this.userLang, this.event.default_locale).name }),
            buttons: [{
                text: this.translate.instant('BUTTON_CANCEL'),
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => { }
            }, {
                text: this.translate.instant('KEYWORD_DELETE_KEYWORD_PERMANENTLY'),
                role: '',
                cssClass: 'primary',
                handler: () => { this.deleteKeywordFromServer(keyword) }
            }]
        });
        await alert.present();
    }

    /**
     * Deletes an object from the server
     *
     * @param keyword
     */
    public deleteKeywordFromServer(keyword: MarketplaceKeyword) {
        this.marketplaceService.deleteKeyword(this.marketplace.id, keyword.id).subscribe(
            (success) => {
                // remove keyword selection
                this.toggleKeyword(keyword);

                // remove local keyword suggestions from marketplace containers
                this.marketplace.containers.forEach((c, i) => {
                    this.marketplace.containers[i].keywords = this.marketplace.containers[i].keywords.filter((k) => {
                        return k.id != keyword.id
                    })
                });
                this.overlayService.showSuccess(success.message);
            },
            (error) => {
                // this.onChangeLoading.emit(false);
                // this.onChangeDataLoading.emit(false);
                this.overlayService.showError(error.error.message);
            });
    }

    /**
     * on destroy
     *
     * @return void
     */
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
