<ion-header *ngIf="modal"
            class="ion-no-shadow ion-no-border">
    <ion-toolbar>
        <ng-container>
            <ion-title class="full">{{ 'SELECT_KEYWORDS_MODAL_HEADER' | translate }}</ion-title>
            <ion-buttons slot="end">
                <ion-button (click)="backClicked()">
                    <ion-icon slot="icon-only"
                              color="dark"
                              name="close"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ng-container>
    </ion-toolbar>
</ion-header>

<ion-button *ngIf="!plt.sizeSm && favoriteSaving && pivots && pivots.length > 0"
            (click)="resetPreferences()"
            color="secondary"
            size="small"
            class="favorite">
    {{ 'MARKETPLACE_RESET_FAVORITE_KEYWORDS' | translate }}
    <svg-icon [applyClass]="true"
              src="/assets/icons/ico-close.svg"></svg-icon>
</ion-button>

<div class="scroll-box"
     [ngClass]="{'fixed': !modal, 'setting': inSetting}">
    <div *ngIf="!loading"
         class="filter-card">

        <div class="filter-box">

            <ion-col class="ion-no-padding">

                <ng-container *ngIf="collapsible && marketplace.containers.length > 1">

                    <ng-container *ngFor="let container of marketplace.containers; let c = index">

                        <app-collapsible [title]="container.translate(userLang, event.default_locale).name"
                                         [icon]=""
                                         [opened]="c == 0 || container.open">
                            <ng-template let-item>
                                <ion-row class="keyword-box ion-no-padding">
                                    <ion-col size="12"
                                             class="multi-columns ion-no-padding">

                                        <!-- Keywords -->
                                        <div class="keyword-list">
                                            <ng-container *ngFor="let keyword of container.keywords">

                                                <ion-chip *ngIf="keyword.approved_at || !keyword.participant_id"
                                                          [ngClass]="{'enabled': isSelected(keyword)}"
                                                          (click)="toggleKeyword(keyword)">
                                                    <ion-label>
                                                        {{ keyword.translate(userLang, event.default_locale).name | cut: 80 }}
                                                    </ion-label>
                                                </ion-chip>
                                                <ion-chip *ngIf="allowKeywordSuggest && !keyword.approved_at && keyword.participant_id && keyword.participant_id == user.selected_participant.id"
                                                          class="suggestion"
                                                          (click)="openDeleteSuggest(keyword)">
                                                    <img img-avatar
                                                         [src]="user?.selected_participant.picture_url">
                                                    <ion-label>{{ keyword.translate(userLang, event.default_locale).name | cut: 80 }}</ion-label>
                                                    <ion-icon name="close"></ion-icon>
                                                </ion-chip>

                                            </ng-container>
                                            <ion-chip *ngIf="allowKeywordSuggest && marketplace.keyword_suggest_allowed"
                                                      class="suggest"
                                                      (click)="presentSuggestKeyword($event, container)">
                                                <ion-label>+ {{ 'DASHBOARD_MATCHING_LIST_SUGGEST_KEYWORD' | translate }}</ion-label>
                                            </ion-chip>
                                        </div>

                                    </ion-col>
                                </ion-row>
                            </ng-template>
                        </app-collapsible>

                    </ng-container>
                </ng-container>

                <ng-container *ngIf="!collapsible || marketplace.containers.length == 1">
                    <ng-container *ngFor="let container of marketplace.containers; let c = index">
                        <hr class="full"
                            *ngIf="c > 0">
                        <h4>{{ container.translate(userLang, event.default_locale).name }}</h4>
                        <ion-row class="keyword-box ion-no-padding">
                            <ion-col class="ion-no-padding multi-columns"
                                     size="12">
                                <!-- Keywords -->
                                <div class="keyword-list">
                                    <ng-container *ngFor="let keyword of container.keywords">

                                        <ion-chip *ngIf="keyword.approved_at || !keyword.participant_id"
                                                  [ngClass]="{'enabled': isSelected(keyword)}"
                                                  (click)="toggleKeyword(keyword)">
                                            <ion-label>
                                                {{ keyword.translate(userLang, event.default_locale).name | cut: 80 }}
                                            </ion-label>
                                        </ion-chip>
                                        <ion-chip *ngIf="allowKeywordSuggest && !keyword.approved_at && keyword.participant_id && keyword.participant_id == user.selected_participant.id"
                                                  class="suggestion"
                                                  (click)="openDeleteSuggest(keyword)">
                                            <img img-avatar
                                                 [src]="user?.selected_participant.picture_url">
                                            <ion-label>{{ keyword.translate(userLang, event.default_locale).name | cut: 80 }}</ion-label>
                                            <ion-icon name="close"></ion-icon>
                                        </ion-chip>

                                    </ng-container>
                                    <ion-chip *ngIf="allowKeywordSuggest && marketplace.keyword_suggest_allowed"
                                              class="suggest"
                                              (click)="presentSuggestKeyword($event, container)">
                                        <ion-label>+ {{ 'DASHBOARD_MATCHING_LIST_SUGGEST_KEYWORD' | translate }}</ion-label>
                                    </ion-chip>
                                </div>

                            </ion-col>
                        </ion-row>

                    </ng-container>
                </ng-container>
            </ion-col>

        </div>

    </div>
</div>

<ion-footer *ngIf="modal">
    <ion-row class="ion-no-padding"
             [ngClass]="{'action-buttons': plt.sizeSm}">
        <ion-col *ngIf="favoriteSaving && pivots && pivots.length > 0"
                 class="ion-no-padding">
            <ion-button (click)="resetPreferences()"
                        color="secondary">
                {{ 'MARKETPLACE_RESET_FAVORITE_KEYWORDS' | translate }}
            </ion-button>
        </ion-col>
        <ion-col class="ion-no-padding ion-text-right">
            <ion-button (click)="seeResults()">
                {{ 'BUTTON_OK' | translate }}
            </ion-button>
        </ion-col>
    </ion-row>
</ion-footer>