<ion-header *ngIf="modal"
            class="ion-no-shadow ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button *ngIf="marketplacePost?.editComment.id"
                        (click)="cancelCommentEdit()">
                <ion-icon slot="icon-only"
                          color="dark"
                          name="arrow-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title *ngIf="!marketplacePost?.editComment.id"
                   class="full"
                   [innerHTML]="'MARKETPLACE_POST_HEADER_DETAIL' | translate">
        </ion-title>
        <ion-title *ngIf="marketplacePost?.editComment.id"
                   class="full"
                   [innerHTML]="'MARKETPLACE_POST_HEADER_EDIT_COMMENT' | translate">
        </ion-title>
        <ion-buttons slot="end">
            <ion-button *ngIf="!marketplacePost?.editComment.id"
                        (click)="backClicked()">
                <ion-icon slot="icon-only"
                          color="dark"
                          name="close">
                </ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>


<div *ngIf="!plt.sizeSm && marketplacePost?.promoted"
     class="promoted-title">{{ 'MARKETPLACE_POST_PROMOTED' | translate }}</div>

<marketplace-add *ngIf="edit"
                 [marketplacePost]="marketplacePost"
                 [marketplace]="marketplace"
                 [showKeywords]="showKeywords || edit"
                 (onMarketplacePostSended)="marketplacePostSended($event)"
                 (onCanceled)="marketplacePostEditationCanceled($event)">
</marketplace-add>

<div [ngClass]="{'marketplace-detail-box': !loading && modal && marketplacePost}">

    <ion-item *ngIf="!edit && !loading"
              #marketplacePostBox
              [ngClass]="{'main-box': modal,
              'no-comments': !(marketplace.allow_comment || marketplacePost.editComment.id),
              'pinned': marketplacePost.pinned,
              'promoted': marketplacePost.promoted,
              'inactive': marketplacePost.status == 0,
              'declined': marketplacePost.status == 2,
              'modal': modal,
              'long-label': categoryName.length > 12}">

        <ion-grid class="ion-no-padding">

            <ion-row *ngIf="plt.sizeSm"
                     class="status"
                     (click)="showModalDetail()">
                <ion-col *ngIf="marketplacePost.pinned && !modal"
                         class="pinned-title ion-no-padding">
                    <svg-icon [applyClass]="true"
                              src="/assets/icons/ico-thumbtack.svg"></svg-icon>
                    <span>{{ 'MARKETPLACE_POST_PINNED' | translate }}</span>
                </ion-col>

                <ion-col *ngIf="plt.sizeSm && marketplacePost.promoted"
                         class="promoted-title ion-no-padding">{{ 'MARKETPLACE_POST_PROMOTED' | translate }}
                </ion-col>
            </ion-row>

            <ion-row class="header"
                     (click)="showAttendeeDetail(marketplacePost)"
                     [ngClass]="{'clickable' : marketplacePost.getRealParticipant() && user?.canProfileDetails(marketplacePost.getRealParticipant())}">
                <ion-col *ngIf="!marketplacePost.custom_participant_id"
                         class="percentage">
                    <percentage-circle class="percentage-circle"
                                       [matching]="user && user.canMatching(marketplacePost.participant)"
                                       [picture]="user && user.canPicture(marketplacePost.participant)"
                                       [attendee]="marketplacePost.participant">
                    </percentage-circle>
                </ion-col>
                <ion-col *ngIf="marketplacePost.custom_participant_id"
                         class="percentage">
                    <ion-avatar>
                        <img *ngIf="marketplacePost.custom_participant?.getImageUrl()"
                             img-avatar
                             [src]="marketplacePost.custom_participant?.getImageUrl()">
                        <percentage-circle *ngIf="!marketplacePost.custom_participant?.getImageUrl()"
                                           class="percentage-circle"
                                           [picture]="false">
                        </percentage-circle>
                    </ion-avatar>
                </ion-col>
                <ion-col>
                    <strong class="name"
                            *ngIf="!marketplacePost.custom_participant_id">
                        <ng-container *ngIf="!plt.sizeSm">
                            {{ marketplacePost.participant?.getFullName() | cut: 27:'...':false }}
                        </ng-container>
                        <ng-container *ngIf="plt.sizeSm">
                            {{ marketplacePost.participant?.getFullName() }}
                        </ng-container>
                    </strong>
                    <strong class="name"
                            *ngIf="marketplacePost.custom_participant_id">
                        <ng-container *ngIf="!plt.sizeSm">
                            {{ marketplacePost.custom_participant?.getName() | cut: 27:'...':false }}
                        </ng-container>
                        <ng-container *ngIf="plt.sizeSm">
                            {{ marketplacePost.custom_participant?.getName() }}
                        </ng-container>
                    </strong>
                    <span>
                        <ng-container *ngIf="!marketplacePost.custom_participant_id && (marketplacePost.participant?.company || marketplacePost.participant?.city)">
                            <ng-container *ngIf="marketplacePost.participant?.profession">{{ marketplacePost.participant?.profession }} </ng-container>
                            <ng-container *ngIf="marketplacePost.participant?.company">{{ marketplacePost.participant?.company }}</ng-container>
                            <ng-container *ngIf="marketplacePost.participant?.company && marketplacePost.participant?.city">, </ng-container>
                            <ng-container *ngIf="marketplacePost.participant?.city">{{ marketplacePost.participant?.city }}</ng-container>
                        </ng-container>
                        <ng-container *ngIf="marketplacePost.custom_participant_id">
                            {{ marketplacePost.custom_participant?.getPosition() }}
                        </ng-container>
                    </span>

                    <p class="ago">{{marketplacePost.created_at | dateAgo: userLang}}</p>

                </ion-col>
            </ion-row>

            <ion-row class="content ion-no-padding ion-padding-bottom">
                <ion-col *ngIf="marketplacePost.image_url"
                         [ngClass]="{'pointer': marketplacePost.groupedDetails.length > 0 || marketplacePost.contacts.length > 0 || marketplacePost.description?.toString().length > postMaxLength || marketplacePost.sliceLinesPosition}"
                         (click)="showMore()"
                         size="12"
                         size-md="4">
                    <div class="image-preview">
                        <img [src]="plt.createSignedLink(marketplacePost.image_url, false, true) | fullImage">
                    </div>
                </ion-col>
                <ion-col [ngClass]="{'with-image': !plt.sizeSm && marketplacePost.image_url}">
                    <div [ngClass]="{'ad-details': !marketplacePost.longTextVisible, 
                    'pointer': marketplacePost.groupedDetails.length > 0 || marketplacePost.contacts.length > 0 || marketplacePost.description?.toString().length > postMaxLength || marketplacePost.sliceLinesPosition}"
                         (click)="showMore()">
                        <h2>
                            <span>{{ marketplacePost.name }}</span>
                        </h2>
                        <p *ngIf="!marketplacePost.longTextVisible && marketplacePost.groupedDetails.length == 0"
                           class="description">
                            <ng-container *ngIf="(marketplacePost.description?.toString().length > postMaxLength || marketplacePost.sliceLinesPosition)">
                                <span *ngIf="!marketplacePost.sliceLinesPosition"
                                      (click)="showModalDetail()"
                                      [innerHtml]="marketplacePost.description | slice:0:postMaxLength | nl2br">
                                </span>
                                <span *ngIf="marketplacePost.sliceLinesPosition"
                                      (click)="showModalDetail()"
                                      [innerHtml]="marketplacePost.description | slice:0:marketplacePost.sliceLinesPosition | nl2br">
                                </span>
                            </ng-container>
                            <ng-container *ngIf="!(marketplacePost.description?.toString().length > postMaxLength || marketplacePost.sliceLinesPosition)">
                                <span (click)="showModalDetail()"
                                      [innerHtml]="marketplacePost.description | linkify | nl2br | safe:'html'">
                                </span>
                            </ng-container>
                        </p>

                        <p *ngIf="marketplacePost.longTextVisible || marketplacePost.groupedDetails.length > 0"
                           class="description">
                            <span [innerHtml]="marketplacePost.description | linkify | nl2br | safe:'html'"></span>
                        </p>
                    </div>

                    <span *ngIf="!marketplacePost.longTextVisible && marketplacePost.groupedDetails.length == 0 && marketplacePost.contacts.length == 0 && (marketplacePost.description?.toString().length > postMaxLength || marketplacePost.sliceLinesPosition)"
                          class="show-more"
                          (click)="showMore()">
                        {{ 'SHOW_MORE' | translate }}
                        <svg-icon [applyClass]="true"
                                  src="/assets/icons/ico-arrow.svg"
                                  class="collapsible">
                        </svg-icon>
                    </span>

                    <span *ngIf="marketplacePost.longTextVisible && marketplacePost.groupedDetails.length == 0"
                          class="show-less"
                          (click)="marketplacePost.longTextVisible = false">
                        {{ 'SHOW_LESS' | translate }}
                        <svg-icon [applyClass]="true"
                                  src="/assets/icons/ico-arrow.svg"
                                  class="collapsible opened">
                        </svg-icon>
                    </span>

                    <span *ngIf="marketplacePost.groupedDetails.length > 0 || marketplacePost.contacts.length > 0"
                          class="show-more"
                          (click)="showDesignModal()">
                        {{ 'SHOW_MORE' | translate }}
                    </span>

                </ion-col>
            </ion-row>

            <ion-row *ngIf="marketplacePost.price"
                     class="ion-no-padding">
                <ion-col size="3"
                         size-md="2">
                    {{ 'MARKETPLACE_POST_PRICE' | translate }}
                </ion-col>
                <ion-col>
                    <strong>{{ marketplacePost.price }}</strong>
                </ion-col>
            </ion-row>

            <ion-row *ngIf="event?.allow_coins &&  marketplace?.allow_coins && marketplacePost?.coin_price"
                     class="ion-no-padding">
                <ion-col size="3"
                         size-md="2">
                    {{ 'MARKETPLACE_POST_COIN_PRICE' | translate }} ({{ event.coin_name }})
                </ion-col>
                <ion-col>
                    <strong>{{ marketplacePost.coin_price }} {{ event.coin_name }}</strong>
                </ion-col>
            </ion-row>

            <ion-row *ngIf="!modal && user && user.canMessage(marketplacePost.getRealParticipant())"
                     class="ion-no-padding">
                <ion-col size="3"
                         size-md="2">
                    {{ 'ATTENDEE_DETAIL_MESSAGE' | translate }}
                </ion-col>
                <ion-col>
                    <svg-icon (click)="goToMessages(marketplacePost)"
                              [applyClass]="true"
                              class="message"
                              color="secondary"
                              src="/assets/icons/ico-message.svg">
                    </svg-icon>
                </ion-col>
            </ion-row>

            <ion-row *ngIf="marketplacePost.email"
                     class="ion-no-padding">
                <ion-col size="3"
                         size-md="2">
                    {{ 'MARKETPLACE_POST_EMAIL' | translate }}
                </ion-col>
                <ion-col>
                    <strong (click)="openMail(marketplacePost)"
                            class="mail">
                        {{ marketplacePost.email }}
                    </strong>
                </ion-col>
            </ion-row>

            <ion-row *ngIf="marketplacePost.phone"
                     class="ion-no-padding">
                <ion-col size="3"
                         size-md="2">
                    {{ 'MARKETPLACE_POST_PHONE' | translate }}
                </ion-col>
                <ion-col>
                    <strong>{{ marketplacePost.phone }}</strong>
                </ion-col>
            </ion-row>

            <ion-row class="ion-no-padding">
                <ion-col>
                    <div *ngIf="marketplacePost.keywords"
                         class="keyword-list"
                         (click)="showModalDetail()">
                        <ion-label *ngFor="let selectedKeyword of marketplacePost.keywordsUnique"
                                   class="keyword">
                            #{{ selectedKeyword.keyword.translate(userLang, defaultLocale).name | cut: 80 }}
                        </ion-label>
                    </div>
                </ion-col>
            </ion-row>

            <ng-container *ngIf="marketplacePost.coin_price">
                <ion-row *ngIf="marketplace?.allow_coins && event?.allow_coins && (!marketplacePost.participant || user?.id != marketplacePost.participant.user_id)"
                         class="ion-no-padding">
                    <ion-col class="flex ion-justify-content-end">
                        <div class="payment-button"
                             [ngClass]="{'with-pin': !plt.sizeSm && marketplacePost.pinned}">
                            <small><strong>{{ 'COINS_AVAILABLE' | translate }}:<br /> {{user.selected_participant.current_coins}} {{event.coin_name}}</strong></small>
                            <div (click)="purchase()"
                                 class="purchase"
                                 [disabled]="marketplacePost.coin_price > user.selected_participant.current_coins">
                                {{ 'MARKETPLACE_POST_COIN_PRICE_BUY' | translate }}
                            </div>
                        </div>
                    </ion-col>
                </ion-row>
                <ion-row *ngIf="marketplacePost.participant && user?.id == marketplacePost.participant.user_id"
                         class="ion-no-padding">
                    <ion-col class="flex ion-justify-content-end">
                        <div class="payment-button"
                             [ngClass]="{'with-pin': !plt.sizeSm && marketplacePost.pinned}">
                            <div (click)="showOrdersModal()"
                                 class="purchase">
                                {{ 'MARKETPLACE_POST_ORDERS' | translate }}
                            </div>
                        </div>
                    </ion-col>
                </ion-row>
            </ng-container>

            <div class="action-buttons">
                <ng-container *ngIf="marketplacePost.participant && user?.id != marketplacePost.participant.user_id">
                    <a class="button-action-menu"
                       (click)="showActionMenu($event, marketplacePost)">
                        <span class="dot"></span>
                        <span class="dot"></span>
                        <span class="dot"></span>
                    </a>
                </ng-container>
                <div class="category-label"
                     [ngClass]="{'only-label': user?.id == marketplacePost.participant?.user_id}">
                    <ng-container *ngIf="marketplacePost.marketplace_category_id">{{ categoryName }}</ng-container>
                    <ng-container *ngIf="!marketplacePost.marketplace_category_id">{{ 'MARKETPLACE_POST_WITHOUT_CATEGORY' | translate }}</ng-container>
                </div>
                <ng-container *ngIf="user?.id == marketplacePost.participant?.user_id">
                    <div>
                        <svg-icon *ngIf="marketplacePost.status == 0"
                                  [applyClass]="true"
                                  src="/assets/icons/warning.svg"
                                  class="inactive"
                                  (click)="editUnconfirmedModal(marketplacePost)"
                                  [title]="'MARKETPLACE_POST_EDIT_UNCONFIRMED_ICON_TITLE' | translate">
                        </svg-icon>
                        <svg-icon [applyClass]="true"
                                  src="/assets/icons/ico-edit.svg"
                                  class="remove"
                                  (click)="editMarketplacePost(marketplacePost)">
                        </svg-icon>
                        <svg-icon [applyClass]="true"
                                  src="/assets/icons/ico-close.svg"
                                  class="remove"
                                  (click)="deleteMarketplaceConfirmation(marketplacePost.id)">
                        </svg-icon>
                    </div>
                </ng-container>
            </div>

            <ion-row class="footer"
                     [ngClass]="{'with-pin': !plt.sizeSm && marketplacePost.pinned}">
                <ion-col>
                    <div class="response-buttons">

                        <ng-container *ngIf="marketplacePost.headlineCount > 1 || plt.sizeSm">
                            <ng-container *ngFor="let group of marketplacePost.groupedHeadlineDetails; let last = last">
                                <ng-container *ngIf="!group.lang || group.lang == userLang">
                                    <ng-container *ngFor="let detail of group.details">
                                        <ng-container *ngIf="detail.type == 'link'">
                                            <ion-button (click)="openLink(detail.value, detail.options.target)"
                                                        class="pointer">
                                                <ion-icon slot="start"
                                                          name="open-outline">
                                                </ion-icon>
                                                <ng-container *ngIf="detail.options.name">{{ detail.options.name }}</ng-container>
                                                <ng-container *ngIf="!detail.options.name">{{ detail.value }}</ng-container>
                                            </ion-button>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="last">
                                    <br />
                                </ng-container>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="!event?.disableInteraction">
                            <svg-icon *ngIf="!user.selected_participant.invisible"
                                      [applyClass]="true"
                                      src="/assets/icons/ico-like.svg"
                                      class="like"
                                      [ngClass]="{'active': isLiked(marketplacePost)}"
                                      (click)="toggleLike(marketplacePost)">
                            </svg-icon>
                            <svg-icon *ngIf="user.selected_participant.invisible"
                                      [applyClass]="true"
                                      src="/assets/icons/ico-like.svg"
                                      class="like">
                            </svg-icon>
                            <span class="counter like-count">{{marketplacePost.number_of_likes}}</span>

                            <span *ngIf="marketplace.allow_comment || marketplacePost.comments.length > 0"
                                  class="comments"
                                  (click)="showComments()">
                                <svg-icon [applyClass]="true"
                                          src="/assets/icons/ico-message.svg"
                                          class="comment"></svg-icon>
                                <span class="counter comments-count">
                                    {{marketplacePost.comments.length}}
                                    <span *ngIf="marketplacePost.comments.length != 1">{{ 'MARKETPLACE_POST_COMMENT_COUNTER' | translate }}</span>
                                    <span *ngIf="marketplacePost.comments.length == 1">{{ 'MARKETPLACE_POST_COMMENT_COUNTER_SINGULAR' | translate }}</span>
                                </span>
                            </span>
                        </ng-container>

                        <ng-container *ngIf="marketplacePost.headlineCount == 1  && !plt.sizeSm">
                            <ng-container *ngFor="let group of marketplacePost.groupedHeadlineDetails; let last = last">
                                <ng-container *ngIf="!group.lang || group.lang == userLang">
                                    <ng-container *ngFor="let detail of group.details">
                                        <ng-container *ngIf="detail.type == 'link'">
                                            <ion-button (click)="openLink(detail.value, detail.options.target)"
                                                        class="pointer inline">
                                                <ion-icon slot="start"
                                                          name="open-outline">
                                                </ion-icon>
                                                <ng-container *ngIf="detail.options.name">{{ detail.options.name }}</ng-container>
                                                <ng-container *ngIf="!detail.options.name">{{ detail.value }}</ng-container>
                                            </ion-button>
                                        </ng-container>
                                    </ng-container></ng-container>
                            </ng-container>
                        </ng-container>

                        <span *ngIf="!plt.sizeSm && marketplacePost.pinned"
                              class="pinned-title">
                            <svg-icon [applyClass]="true"
                                      src="/assets/icons/ico-thumbtack.svg"></svg-icon>
                            <span>{{ 'MARKETPLACE_POST_PINNED' | translate }}</span>
                        </span>

                        <span *ngIf="marketplacePost.status == 2"
                              class="declined-title">
                            <span>{{ marketplacePost.refuse_reason }}</span>
                        </span>

                    </div>
                </ion-col>
            </ion-row>

            <ion-row *ngIf="marketplacePost.commentsVisible && (modal || !plt.sizeSm)"
                     class="comment-list ion-no-padding">
                <ion-col class="ion-no-padding">
                    <div class="content">
                        <ion-item *ngFor="let comment of marketplacePost.comments"
                                  [ngClass]="{ 'has-error': validationErrors['message'], 'editing': comment.id == marketplacePost.editComment.id }"
                                  class="comment ion-no-padding">
                            <ion-grid>
                                <ion-row class="header ion-no-padding"
                                         (click)="showAttendeeDetailByComment(comment)"
                                         [ngClass]="{'clickable' : comment.getRealParticipant()}">
                                    <ion-col *ngIf="!comment.custom_participant_id"
                                             class="percentage ion-no-padding">
                                        <percentage-circle class="percentage-circle"
                                                           [matching]="user && user.canMatching(comment.participant)"
                                                           [picture]="user && user.canPicture(comment.participant)"
                                                           [attendee]="comment.participant"></percentage-circle>
                                    </ion-col>
                                    <ion-col *ngIf="comment.custom_participant_id"
                                             class="percentage ion-no-padding">
                                        <ion-avatar>
                                            <img *ngIf="comment.custom_participant?.getImageUrl()"
                                                 img-avatar
                                                 [src]="comment.custom_participant?.getImageUrl()">
                                        </ion-avatar>
                                    </ion-col>
                                    <ion-col class="ion-no-padding">
                                        <strong *ngIf="!comment.custom_participant_id">
                                            {{ comment.participant?.firstname }} {{ comment.participant?.lastname }}
                                            <ion-badge *ngIf="showKeywords">{{ comment.participant?.matching_factor | round }}%</ion-badge>
                                        </strong>
                                        <strong *ngIf="comment.custom_participant_id">
                                            {{ comment.custom_participant?.getName() }}
                                        </strong>
                                        <ng-container *ngIf="!comment.custom_participant_id  && (comment.participant?.company || comment.participant?.city)">
                                            <ng-container *ngIf="comment.participant?.profession">{{ comment.participant?.profession }} </ng-container>
                                            <ng-container *ngIf="comment.participant?.company">{{ comment.participant?.company }}</ng-container>
                                            <ng-container *ngIf="comment.participant?.company && comment.participant?.city">, </ng-container>
                                            <ng-container *ngIf="comment.participant?.city">{{ comment.participant?.city }}</ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="comment.custom_participant_id">
                                            {{ comment.custom_participant?.getPosition() }}
                                        </ng-container>

                                        <p class="ago">{{comment.created_at | dateAgo: userLang}}</p>
                                    </ion-col>
                                </ion-row>

                                <div class="action-buttons"
                                     *ngIf="user?.selected_participant?.id == comment.participant_id || user?.id == comment.participant?.user_id">
                                    <svg-icon *ngIf="comment.id != marketplacePost.editComment.id"
                                              [applyClass]="true"
                                              src="/assets/icons/ico-edit.svg"
                                              class="remove"
                                              (click)="editComment(marketplacePost, comment)"></svg-icon>
                                    <svg-icon [applyClass]="true"
                                              src="/assets/icons/ico-close.svg"
                                              class="remove"
                                              (click)="deleteMarketplacePostCommentConfirmation(marketplacePost, comment.id)"></svg-icon>
                                </div>

                                <ion-row class="content">

                                    <ng-container *ngIf="(modal && plt.sizeSm) || comment.id != marketplacePost.editComment.id">
                                        <ion-col size="12"
                                                 class="comment-message">
                                            <span [innerHtml]="comment.message | linkify | nl2br | safe:'html'"></span>
                                        </ion-col>
                                    </ng-container>

                                    <ng-container *ngIf="(!modal) && comment.id == marketplacePost.editComment.id">
                                        <ion-col size="12"
                                                 class="ion-no-padding">
                                            <ion-textarea placeholder="{{ 'PLACEHOLDER_FEED_COMMENT' | translate }}"
                                                          ieTextareaFix
                                                          name="commentMessage"
                                                          inputmode="text"
                                                          spellcheck="false"
                                                          autocapitalize="off"
                                                          #commentMessage="ngModel"
                                                          rows="1"
                                                          [autoGrow]="true"
                                                          [(ngModel)]="marketplacePost.editComment.message">
                                            </ion-textarea>

                                            <span class="error-message"
                                                  *ngIf="validationErrors['message']">
                                                {{ validationErrors['message'] }}
                                            </span>
                                        </ion-col>
                                        <ion-col size="12"
                                                 class="ion-no-padding ion-text-right">
                                            <ion-button *ngIf="marketplacePost?.editComment.id"
                                                        class="btn-close"
                                                        (click)="cancelCommentEdit()">
                                                {{ 'BUTTON_CANCEL' | translate }}
                                            </ion-button>
                                            <ion-button color="primary"
                                                        class="post-comment-button"
                                                        [disabled]="submit"
                                                        (click)="postComment(marketplacePost)">
                                                {{ 'BUTTON_SAVE' | translate }}
                                            </ion-button>
                                        </ion-col>
                                    </ng-container>

                                </ion-row>
                            </ion-grid>

                        </ion-item>
                    </div>
                    <div class="new-comment"
                         *ngIf="(!modal) && !marketplacePost.editComment.id && marketplace.allow_comment">
                        <ion-grid class="ion-no-padding ion-padding-top">
                            <ion-row [ngClass]="{ 'has-error': validationErrors['message'] }">
                                <ion-col size="9">
                                    <ion-textarea placeholder="{{ 'PLACEHOLDER_FEED_COMMENT' | translate }}"
                                                  ieTextareaFix
                                                  name="commentMessage"
                                                  inputmode="text"
                                                  spellcheck="false"
                                                  autocapitalize="off"
                                                  #commentMessage="ngModel"
                                                  rows="1"
                                                  [autoGrow]="true"
                                                  [(ngModel)]="marketplacePost.editComment.message">
                                    </ion-textarea>
                                </ion-col>
                                <ion-col size="3">
                                    <ion-button color="primary"
                                                class="post-comment-button"
                                                [disabled]="submit"
                                                (click)="postComment(marketplacePost)">
                                        {{ 'BUTTON_SAVE' | translate }}
                                    </ion-button>
                                </ion-col>
                            </ion-row>
                            <ion-row *ngIf="validationErrors['message']">
                                <ion-col size="9">
                                    <span class="error-message">
                                        {{ validationErrors['message'] }}
                                    </span>
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </div>
                </ion-col>
            </ion-row>

        </ion-grid>

    </ion-item>

    <ng-container *ngIf="loading">
        <div class="content-centered-column">
            <div class="content-centered-row">
                <ion-spinner></ion-spinner>
            </div>
        </div>
    </ng-container>

    <div *ngIf="!loading && modal && marketplacePost && (marketplace.allow_comment || marketplacePost.editComment.id) && !user.selected_participant.invisible"
         class="chat-box-form">
        <ion-row class="ion-no-padding">
            <ion-col size="12"
                     size-sm="9"
                     class="ion-no-padding">
                <ion-textarea rows="1"
                              ieTextareaFix
                              [autoGrow]="true"
                              (ionFocus)="plt.checkResize() "
                              (ionBlur)="plt.checkResize(false)"
                              cols="20"
                              [(ngModel)]="marketplacePost.editComment.message"
                              [placeholder]="'PLACEHOLDER_FEED_COMMENT' | translate">
                </ion-textarea>
                <div *ngIf="plt.sizeSm"
                     class="send-icon">
                    <svg-icon *ngIf="!submit"
                              [applyClass]="true"
                              (click)="postComment(marketplacePost)"
                              class="fill-primary"
                              src="/assets/icons/ico-send.svg">
                    </svg-icon>
                    <ion-spinner *ngIf="submit"
                                 name="dots">
                    </ion-spinner>
                </div>
            </ion-col>
            <ion-col *ngIf="!plt.sizeSm"
                     size="12"
                     size-sm="3"
                     class="ion-no-padding">
                <ion-button color="primary"
                            class="post-comment-button"
                            [disabled]="submit"
                            (click)="postComment(marketplacePost)">
                    {{ 'BUTTON_SAVE' | translate }}
                </ion-button>
            </ion-col>
        </ion-row>
    </div>
</div>