import { Component, ElementRef, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { ActivatedRoute, Router, NavigationEnd, Scroll, RouterEvent } from '@angular/router';

// ionic
import { Events } from "../../helpers/events";
import { ModalController } from '@ionic/angular';
import { Platform } from "@ionic/angular";

// rxjs
import { Subject, Subscription } from "rxjs";
import { takeUntil, debounceTime, take, startWith } from "rxjs/operators";

// components

// services
import { BroadcastService } from "../../services/broadcast.service";
import { ConversationService } from "../../services/conversation.service";
import { ChatGroupsService } from "../../services/chat-groups.service";
import { EventService } from "../../services/event.service";
import { ParticipantService } from "../../services/participant.service";
import { PlatformService } from "../../services/platform.service";
import { RouterExtService } from "../../services/router-ext.service";
import { UserService } from "../../services/user.service";

// models
import { Event } from "../../models/event";
import { Broadcast } from "../../models/broadcast";
import { Conversation } from "../../models/conversation"
import { ChatGroup } from "../../models/chat-group";
import { Participant } from "../../models/participant";
import { User } from "../../models/user";
import { OverlayService } from 'src/app/services/overlay.service';

@Component({
    selector: 'app-chat-groups-list',
    templateUrl: './chat-groups-list.component.html',
    styleUrls: ['./chat-groups-list.component.scss'],
})
export class ChatGroupsListComponent implements OnInit, OnDestroy {

    /**
     * if silent mode, triggers to refresh/navigate message page are not fired
     *
     * @type {boolean}
     */
    @Input() silent: boolean = true;

    /**
     * chat box
     *
     * @type ViewChild
     */
    @ViewChild('chatList') private scrollContainer: ElementRef;

    @Input() external;

    /**
     * unsubscribe subject
     *
     * @type {Subject<void>}
     */
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    /**
     * subscription for event changes subject
     *
     * @type {Subscription}
     */
    private subscriptionEvent: Subscription;

    /**
    * subscription for route changes subject
    *
    * @type {Subscription}
    */
    private subscription: Subscription;

    /**
     * event
     *
     * @type {Event}
     */
    public event: Event;

    /**
     * user
     *
     * @type {User}
     */
    public user: User;

    /**
     * conversations
     *
     * @type Conversation[]
     */
    public conversations: Conversation[] = [];

    /**
     * broadcasts
     *
     * @type Broadcast[]
     */
    public broadcastsConversation: Broadcast[] = [];

    /**
     * collection
     *
     * @type any[]
     */
    public collection: any[] = [];

    /**
     * chat group channels
     *
     * @type ChatGroup[]
     */
    public assignedChatGroups: ChatGroup[] = [];

    /**
     * show loading
     *
     * @type {boolean}
     */
    public loading: boolean = true;

    /**
     * selected participantfor chat
     *
     * @type {number}
     */
    public selectedParticipantId: number;

    /**
     * selected participantfor chat
     *
     * @type {number}
     */
    public selectedBroadcast: boolean = false;

    /**
     * selected channel chat
     *
     * @type {number}
     */
    public selectedChatGroupId: number;

    /**
     * show join channel button
     *
     * @type {boolean}
     */
    visibleJoinChatGroup: boolean = false;

    /**
     * show chat group section
     *
     * @type {boolean}
     */
    visibleChatGroup: boolean = false;

    /**
     * number of deleted conversations
     *
     * @type number
     */
    public deletedConversationsCount: number;

    // handlers for events
    eventChangeHandler;
    refreshHandler;

    constructor(
        private appEvents: Events,
        private routerExtService: RouterExtService,
        public plt: PlatformService,
        public modalController: ModalController,
        public broadcastService: BroadcastService,
        public conversationService: ConversationService,
        public chatGroupsService: ChatGroupsService,
        public eventService: EventService,
        public participantService: ParticipantService,
        public userService: UserService,
        public route: ActivatedRoute,
        public router: Router,
        public platform: Platform,
        public overlayService: OverlayService
    ) {
    }

    /**
     * on init
     *
     * @return void
     */
    ngOnInit() {
        this.eventChangeHandler = () => {
            this.resetDetail();
            // this.attachEvents();
        }

        // attach hooks on event change
        this.appEvents.subscribe('event:changed', this.eventChangeHandler);

        this.attachEvents();
    }

    attachEvents() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();

        this.refreshHandler = (chatGroupId?, reset: boolean = false) => {
            if (chatGroupId) {
                this.resetDetail();
                this.selectedChatGroupId = chatGroupId;
            } else {

                if (reset) {
                    this.resetDetail();
                }
            }

            this.refresh();
        }

        this.appEvents.unsubscribe('messages:refresh:list');
        this.appEvents.subscribe('messages:refresh:list', this.refreshHandler);

        this.ngUnsubscribe = new Subject<void>();

        this.userService.getCurrentUser().pipe(
            takeUntil(this.ngUnsubscribe),
            debounceTime(0)
        ).subscribe((user) => {
            if (user.id) {

                this.plt.chatInit = false;

                // reset when switching user, we need to reset after every change of user...
                if ((!this.user || this.user.selected_participant_id != user.selected_participant_id) && this.plt.isUrl(this.plt.defaultLink + 'messages', true)) {
                    this.resetDetail();
                    this.silent = false;
                } else {
                    this.silent = true;
                }

                this.user = user;

                // cancel previous subscription
                if (this.subscriptionEvent) {
                    this.subscriptionEvent.unsubscribe();
                }

                this.subscriptionEvent = this.eventService.getCurrentEvent().pipe(
                    takeUntil(this.ngUnsubscribe),
                    debounceTime(0)
                ).subscribe((event) => {
                    this.event = event;

                    this.verifyUrl(null, this.plt.chatInit);

                    if (this.subscription) {
                        this.subscription.unsubscribe();
                    }

                    this.subscription = this.router.events.pipe(
                        takeUntil(this.ngUnsubscribe),
                        // add delay for first opneing which is handled in event subscription
                        debounceTime(200)
                    ).subscribe((event: any) => {

                        // for route event event get route event
                        if ((event instanceof Scroll)) {
                            event = <RouterEvent>event.routerEvent;
                        }

                        // using url from NavigationEnd instance, because route.snapshot was not sometimes updated...
                        if (event instanceof RouterEvent && event instanceof NavigationEnd && this.plt.isConnected) {
                            // reset selected page in case of broadcast to force page loading after URL changes
                            // otherwise loading will be stuck
                            this.selectedBroadcast = false;
                            this.verifyUrl(event.url);
                        }
                    });

                    this.plt.activeSubscriptions.push(this.subscription);

                });
                this.plt.activeSubscriptions.push(this.subscriptionEvent);

            } else {
                this.user = null;
            }
        });

        if (this.plt.is('cordova') && this.plt.is('android')) {
            this.platform.backButton.pipe(
                takeUntil(this.ngUnsubscribe)
            ).subscribe(() => {
                this.modalController.getTop().then((v) => {
                    if (v) {
                        this.modalController.dismiss();
                    } else {
                        if (this.plt.isUrl('tabsmessages') && this.plt.isConnected) {
                            this.resetDetail();
                            // refresh page content
                            this.refresh();
                            // scroll to top
                            if (this.scrollContainer) {
                                this.scrollContainer.nativeElement.scrollTop = 0;
                            }
                        }
                    }
                });
            });
        } else {
            window.addEventListener("popstate", (event) => {
                this.modalController.getTop().then((v) => {
                    if (v) {
                        this.modalController.dismiss();
                    } else {
                        // check url type, use reset and refresh only on mobile devices
                        if (this.plt.isUrl('tabsmessages') && this.plt.isConnected) {
                            // if (this.selectedBroadcast && this.selectedBroadcast) {
                            //     this.routerExtService.softBack();
                            // } else {
                            // remove latest from history
                            this.routerExtService.popHistory();
                            // refresh page content
                            this.resetDetail();
                            this.refresh();
                            // scroll to top
                            if (this.scrollContainer) {
                                this.scrollContainer.nativeElement.scrollTop = 0;
                            }
                            // }
                        }
                    }
                });
            });
        }
    }

    /**
      * refresh list
      *
      * @return void
      */
    public refresh() {

        // in case of no connection, just disable
        if (!this.plt.isConnected) {
            return;
        }

        // Be sure to load alway latest event
        this.eventService.getCurrentEvent().pipe(
            take(1)
        ).subscribe(
            (event) => {

                this.event = event;
                if (this.event && this.event.id) {

                    if (!event.disableMessages) {

                        let subscription = this.chatGroupsService.getListByEvent(this.event.id, { 'chat_group_id': this.selectedChatGroupId }).subscribe(
                            (success) => {

                                if (!!success.message) {
                                    this.overlayService.showError(success.message, true);
                                }

                                // process broadcast
                                this.broadcastsConversation = [];

                                // use only last broadcast to
                                let unread = 0;

                                if (success.broadcasts && success.broadcasts.length > 0) {
                                    success.broadcasts.forEach((broadcast) => {
                                        unread += broadcast.pivot.unread;
                                    });
                                    this.broadcastsConversation.push(new Broadcast(success.broadcasts[success.broadcasts.length - 1]));
                                    this.broadcastsConversation[0].pivot.unread = unread;
                                }

                                // process conversations
                                this.conversations = [];

                                // count new messages
                                if (success.conversations.length > 0) {

                                    success.conversations.forEach((conversation) => {
                                        if (conversation.participants.length) {
                                            this.conversations.push(new Conversation(conversation));
                                        }
                                    });

                                    // order by unread_messages_count DESC
                                    this.conversations = this.conversations.sort(function (a, b) {
                                        if (a.messages.length < b.messages.length) {
                                            return 1;
                                        }
                                        if (a.messages.length > b.messages.length) {
                                            return -1;
                                        }
                                        return 0;
                                    });

                                    this.deletedConversationsCount = success.conversations.length - this.conversations.length;
                                }

                                // process chat groups
                                this.assignedChatGroups = [];
                                this.visibleJoinChatGroup = false;
                                this.visibleChatGroup = false;

                                if (success.chat_groups.length > 0) {
                                    success.chat_groups.forEach(chatGroup => {
                                        // show only chat group when user is participating
                                        if (chatGroup.joined) {
                                            this.assignedChatGroups.push(new ChatGroup(chatGroup))
                                        }
                                    });

                                    // order by unread_messages_count DESC
                                    this.assignedChatGroups = this.assignedChatGroups.sort(function (a, b) {
                                        if (a.unread_messages_count < b.unread_messages_count) {
                                            return 1;
                                        }
                                        if (a.unread_messages_count > b.unread_messages_count) {
                                            return -1;
                                        }
                                        return 0;
                                    });
                                }

                                if (success.chat_groups.length != this.assignedChatGroups.length) {
                                    // for now we will hide join channel section
                                    this.visibleJoinChatGroup = true;
                                }

                                if (success.chat_groups.length > 0) {
                                    // for now we will hide join channel section
                                    this.visibleChatGroup = true;
                                }

                                if (!this.silent && (!this.plt.tabMenu || this.plt.isUrl(this.plt.defaultLink.replace('/', '') + 'messages', true))) {
                                    // if there is nothing selected, choose first chat group if any
                                    // we don't open first available on mobile, there is only list of chats...
                                    if (!this.plt.isUrl(this.plt.defaultLink + 'messages/direct', true)
                                        && !this.plt.isUrl(this.plt.defaultLink + 'messages/channels', false)
                                        && !this.plt.tabMenu
                                        && !this.selectedBroadcast && !this.selectedParticipantId && !this.selectedChatGroupId
                                    ) {
                                        if (this.assignedChatGroups.length > 0) {

                                            // take first chat group and load it's content
                                            this.loadChatGroup(this.assignedChatGroups[0]);

                                        } else if (this.broadcastsConversation.length > 0) {

                                            // load broadcast if there is any
                                            this.loadBroadcast();

                                        } else if (this.conversations.length) {

                                            // take first direct conversation and load it
                                            this.setConversationParticipant(this.conversations[0].participants[0], this.conversations[0]);

                                        } else {
                                            // load empty message page
                                            this.loadEmpty({ reset: true });
                                        }
                                    } else {
                                        // load selected chat group if selected from url
                                        if (this.selectedChatGroupId) {
                                            let existing = false;

                                            this.assignedChatGroups.concat(Object.values(success.streams)).forEach(
                                                (chatGroup) => {
                                                    if (chatGroup.id == this.selectedChatGroupId) {
                                                        existing = true;
                                                        this.loadChatGroup(chatGroup);
                                                    }
                                                });

                                            if (!existing) {
                                                // disabled or removed chat group
                                                this.resetDetail();
                                                this.refresh();
                                            }
                                        } else if (this.selectedParticipantId) {
                                            // load selected direct chat if selected from url
                                            let existing = false;
                                            this.conversations.forEach((conversation) => {
                                                if (conversation.participants[0].id == this.selectedParticipantId) {
                                                    existing = true;
                                                    this.setConversationParticipant(conversation.participants[0], conversation);
                                                }
                                            });

                                            if (!existing) {

                                                this.participantService.getById(+ this.selectedParticipantId).subscribe(
                                                    (participant) => {
                                                        let attendee = new Participant(participant);
                                                        let conversation = new Conversation();
                                                        conversation.participants = [attendee];
                                                        // add this new conversation to list of conversations
                                                        this.conversations.push(conversation);

                                                        this.setConversationParticipant(attendee);
                                                    });
                                            }
                                        } else {
                                            if (!this.plt.isUrl(this.plt.defaultLink + 'messages/channels', false)
                                                && (!this.plt.tabMenu || this.plt.isUrl(this.plt.defaultLink + 'messages/broadcast', false))
                                                && this.broadcastsConversation.length > 0
                                            ) {
                                                this.loadBroadcast();
                                            } else {
                                                // disable loading in main message part
                                                if (!this.plt.isUrl(this.plt.defaultLink + 'messages/channels', false)) {
                                                    this.appEvents.publish('messages:empty', { reset: this.plt.tabMenu });
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    // disable loading in main message part
                                    this.appEvents.publish('messages:empty', { reset: this.plt.tabMenu });
                                }
                            }
                        );

                        this.plt.activeSubscriptions.push(subscription);
                    } else {
                        // only load broadcast
                        this.loadBroadcast();
                    }
                }
            });
    }

    /**
     * assign chat group to messages box page
     *
     * @param {ChatGroup} selectedChatGroup
     *
     * @return void
     */
    public loadChatGroup(selectedChatGroup: ChatGroup) {
        this.selectedParticipantId = null;
        this.selectedChatGroupId = selectedChatGroup.id;
        this.selectedBroadcast = false;

        // this will mark item in direct messages as read - remove notification...
        setTimeout(() => {
            selectedChatGroup.unread_messages_count = 0;
        }, 1000);

        let link = this.plt.defaultLink + '/messages/channel/' + this.selectedChatGroupId;

        // decide if we should replace history, because of not existing messages page on desktop
        let replace: boolean = !this.plt.tabMenu && this.plt.isUrl(this.plt.defaultLink.replace('/', '') + 'messages');

        // when we have already opened message page, we just trigger events to load data
        if (this.plt.isUrl(this.plt.defaultLink.replace('/', '') + 'messages', true)) {
            this.appEvents.publish('messages:chatgroup', selectedChatGroup);
            this.routerExtService.softNavigate(link, replace);
        } else {
            // otherwise we need to redirect to messages page and trigger event for loading
            this.plt.navigate(link);
        }
    }

    /**
     * set actual attendee to show his name directly in chat header
     *
     * @param attendee
     *
     * @return void
     */
    public setConversationParticipant(attendee, conversation?, resetLinkedItem = false) {
        this.selectedParticipantId = attendee.id;
        this.selectedChatGroupId = null;
        this.selectedBroadcast = false;

        // this will mark item in direct messages as read - remove notification...
        if (conversation) {
            setTimeout(() => {
                conversation.messages = [];
            }, 1000);
        }

        let link = this.plt.defaultLink + '/messages/direct/' + this.selectedParticipantId;

        // decide if we should replace history, because of not existing messages page on desktop
        let replace: boolean = !this.plt.tabMenu && this.plt.isUrl(this.plt.defaultLink.replace('/', '') + 'messages');

        // when we have already opened message page, we just trigger events to load data
        if (this.plt.isUrl(this.plt.defaultLink.replace('/', '') + 'messages', true)) {
            this.appEvents.publish('messages:participant', attendee, conversation, resetLinkedItem);
            this.routerExtService.softNavigate(link, replace);
        } else {
            // otherwise we need to redirect to messages page and trigger event for loading
            this.plt.navigate(link)
        }
    }

    /**
     * assign broadcast to messages page
     *
     * @param {Broadcast} selectedBroadcast
     *
     * @return void
     */
    public loadBroadcast(selectedBroadcast?: Broadcast) {
        this.selectedParticipantId = null;
        this.selectedChatGroupId = null;
        this.selectedBroadcast = true;

        let link = this.plt.defaultLink + '/messages/broadcast';

        // decide if we should replace history, because of not existing messages page on desktop
        let replace: boolean = !this.plt.tabMenu && this.plt.isUrl(this.plt.defaultLink.replace('/', '') + 'messages');

        // when we have already opened message page, we just trigger events to load data
        if (this.plt.isUrl(this.plt.defaultLink.replace('/', '') + 'messages', true)) {
            this.appEvents.publish('messages:broadcast', selectedBroadcast);
            this.routerExtService.softNavigate(link, replace);
        } else {
            // otherwise we need to redirect to messages page and trigger event for loading
            this.plt.navigate(link);
        }
    }

    /**
     * load empty messages page
     *
     * @return void
     */
    public loadEmpty(setting: { reset: boolean } = { reset: false }) {
        // when we have already opened message page, we just trigger events to load data
        if (this.plt.isUrl(this.plt.defaultLink.replace('/', '') + 'messages', true)) {
            // set proper component state
            this.appEvents.publish('messages:empty', setting);
            this.routerExtService.softNavigate(this.plt.defaultLink + '/messages');
        } else {
            // otherwise we need to redirect to messages page and trigger event for loading
            this.plt.navigate(this.plt.defaultLink + '/messages');
        }
    }

    /**
     * add new channel
     *
     * @return void
     */
    public addChatGroup() {

        let link = this.plt.defaultLink + '/messages/channels';

        // decide if we should replace history, because of not existing messages page on desktop
        let replace: boolean = !this.plt.tabMenu && this.plt.isUrl(this.plt.defaultLink.replace('/', '') + 'messages');

        // when we have already opened message page, we just trigger events to load data
        if (this.plt.isUrl(this.plt.defaultLink.replace('/', '') + 'messages', true)) {
            this.appEvents.publish('messages:chatgroup:add');
            this.routerExtService.softNavigate(link, replace);
        } else {
            // otherwise we need to redirect to messages page and trigger event for loading
            this.plt.navigate(link);
        }
    }

    /**
     * navigate app to list of attendees
     *
     * @return void
     */
    public goToAttendees() {
        this.router.navigate([this.plt.defaultLink + '/home/attendees/list']);
    }

    /**
     * collection sorting by date
     *
     * @param collection
     *
     * @return {Conversation[]}
     */
    private sortCollection(collection: any[]): any[] {
        return collection.sort((n1, n2) => {
            let n1value: Date = new Date;
            let n2value: Date = new Date;

            if (n1 instanceof Broadcast) {
                n1value = n1.date_sent_at;
            } else if (n1 instanceof Conversation) {
                n1value = n1.latest_message.date_created_at;
            }

            if (n2 instanceof Broadcast) {
                n2value = n2.date_sent_at;
            } else if (n2 instanceof Conversation) {
                n2value = n2.latest_message.date_created_at;
            }

            return n1value < n2value ? 1 : n1value == n2value ? 0 : -1;
        });
    }

    /**
     * reset detail, messages to default state
     *
     * @return void
     */
    public resetDetail(redirect: boolean = false) {
        //this.searchMessage = '';
        if (!this.external) {
            this.plt.hideBottomMenu = false;
        }
        this.selectedBroadcast = false;
        this.selectedParticipantId = null;
        this.selectedChatGroupId = null;

        if (this.plt.tabMenu
            && redirect
            && this.plt.isUrl(this.plt.defaultLink.replace('/', '') + 'messages', true, window.location.pathname)
        ) {
            // reset view to messages
            this.routerExtService.softNavigate(this.plt.defaultLink + '/messages');
        }
    }

    /**
      * verify actual app url and refresh component if needed
      *
      * @param {boolean} init load data for first time
      *
      * @return void
      */
    private verifyUrl(path: string = null, init: boolean = false) {

        if (!path) {
            path = window.location.pathname;
        }

        // cancel verification in case of different link and event in change
        if (this.plt.eventInChange || !this.plt.isUrl(this.plt.defaultLink.replace('/', '') + 'messages', true, path) || init) {
            return false;
        }

        // only broadcast is allowed in case of disabled messaging
        if (this.event.disableMessages && !this.plt.isUrl(this.plt.defaultLink.replace('/', '') + 'messages/broadcast', true)) {
            // redirect to broadcast
            this.router.navigate([this.plt.defaultLink + '/messages/broadcast']);
            return false;
        }
        
        this.plt.chatInit = true;

        if (this.plt.isUrl(this.plt.defaultLink + 'messages/channels', true)) {
            this.resetDetail();
            this.refresh();
            // check if user can see channel join page
            // TODO[jg] - merge this condition and template condition to better variable
            if ((this.visibleJoinChatGroup
                || this.event.can(this.user.selected_participant.group_id, 'allow_chat_group_creation')
                || this.event.can(this.user.selected_participant.group_id, 'allow_private_chat_group_creation')
                || this.event.can(this.user.selected_participant.group_id, 'allow_secured_chat_group_creation'))
                && !this.user.selected_participant.not_contactable) {
                this.addChatGroup();
            }
        } else {

            // verify url to check if direct chat should be selected
            if (this.plt.isUrl(this.plt.defaultLink.replace('/', '') + 'messages/direct', true)) {
                let id = parseInt(path.replace(this.plt.defaultLink + '/messages/direct/', '').replace('?call=true', ''));
                if (!isNaN(id) && id > 0) {
                    this.resetDetail();
                    this.selectedParticipantId = id;
                    this.silent = false;
                    this.refresh();
                }
            } else {
                // verify url to check if chat group should be selected
                if (this.plt.isUrl(this.plt.defaultLink.replace('/', '') + 'messages/channel/', true)) {
                    let id = parseInt(path.replace(this.plt.defaultLink + '/messages/channel/', ''));
                    if (!isNaN(id) && id > 0) {
                        this.resetDetail();
                        this.selectedChatGroupId = id;
                        this.refresh();
                    }
                } else {
                    // verify url to check if broadcast should be selected
                    if (this.plt.isUrl(this.plt.defaultLink.replace('/', '') + 'messages/broadcast', true)) {
                        if (!this.selectedBroadcast) {
                            this.resetDetail();
                            this.selectedBroadcast = true;
                            this.refresh();
                        }
                    } else {
                        if (this.plt.isUrl(this.plt.defaultLink.replace('/', '') + 'messages', true)) {
                            this.resetDetail();
                            this.refresh();
                        } else {
                            this.resetDetail();
                            // stop loading in main section
                            // needs to be triggered on mobile
                            this.appEvents.publish('messages:empty', { reset: false });
                        }
                    }
                }
            }
        }
    }

    /**
     * on destroy
     *
     * @return void
     */
    ngOnDestroy(): void {
        if (this.eventChangeHandler) {
            this.appEvents.unsubscribe('event:changed', this.eventChangeHandler);
        }

        if (this.refreshHandler) {
            this.appEvents.unsubscribe('messages:refresh:list', this.refreshHandler);
        }

        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
