import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, RouterEvent, Scroll } from '@angular/router';

// ionic
import { MenuController, Platform } from '@ionic/angular';

// ionic native
import { SignInWithApple, AppleSignInResponse, AppleSignInErrorResponse, ASAuthorizationAppleIDRequest } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';

// social logins
import { linkedInAuthConfig } from '../../settings/linkedin.settings';
import { OAuthService } from 'angular-oauth2-oidc';
import { OauthCordova } from "ng2-cordova-oauth/platform/cordova";
import { LinkedIn } from "ng2-cordova-oauth/core";


// rxjs
import { Subject } from 'rxjs';
import { filter, takeUntil } from "rxjs/operators";

// extends
import { BasePage } from "../base";

// models
import { User } from "../../models/user";
import { Participant } from "../../models/participant";

// services
//import { PushPageService } from "../../services/push-page.service"; TODO
import { AuthenticationService } from "../../services/authentication.service";
import { ParticipantService } from '../../services/participant.service';
import { UserService } from '../../services/user.service';
import { StorageService } from "../../services/storage.service";

import { QrCodeReaderComponent } from '../../components/qr-code-reader/qr-code-reader.component';
import { SelectGroupForJoinComponent } from '../../components/select-group-for-join/select-group-for-join.component';


// config
import { environment } from "../../../environments/environment";


import { log } from "../../helpers/log";


@Component({
    selector: 'app-login',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss'],
})
export class LoginPage extends BasePage implements OnInit, AfterViewInit {

    /**
     * unsubscribe subject
     *
     * @type {Subject<void>}
     */
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    /**
     * Form
     *
     * @type ViewChild
     */
    @ViewChild('f') form;

    /**
     * email input
     *
     * @type ViewChild
     */
    @ViewChild('emailInput') emailInput;

    /**
     * user model
     *
     * @type User
     */
    public user: User = new User;

    /**
     * loaded state
     *
     * @type {boolean}
     */
    public loaded: boolean = false;

    /**
     * reconfirmation state
     *
     * @type {boolean}
     */
    public reconfirmation: boolean = false;

    /**
     * enable reconfirmation
     *
     * @type {boolean}
     */
    public enableReconfirmation: boolean = false;

    /**
     * enable registration
     *
     * @type {boolean}
     */
    public enableRegistration: boolean = false;

    /**
     * static environment config
     *
     * @type {Object}
     */
    public environment: Object = environment;

    /**
     * submitted
     *
     * @type {boolean}
     */
    public submitted: boolean = false;

    /**
     * validate state
     *
     * @type {boolean}
     */
    public validate: boolean = false;

    /**
     * linkedin provider
     *
     * @type {LinkedIn}
     */
    private linkedinProvider: LinkedIn = new LinkedIn({
        clientId: environment.linkedIn.clientId,
        appScope: ['email', 'profile', 'openid']
    });

    /**
     * event key code
     *
     * @type {string}
     */
    public eventKey: string;

    /**
     * should we show event key input?
     *
     * @type {boolean}
     */
    public showEventKey: boolean = false;


    /**
     * should we show keep user signed in
     *
     * @type {boolean}
     */
    public keepLoggedIn: boolean = false;

    /**
     * check if user is participatin event ID
     *
     * @type {number}
     */
    protected checkEventid: number = 0;

    // group helper arrays for custom pages login
    // array of object {id:number, event_key: string}
    protected groupKeys = [];
    // array of event IDs which should not be visible
    protected hiddenGroups = [];
    // array of order for event IDs list
    protected groupOrder = [];

    /**
     * constructor
     *
     * @param signInWithApple
     * @param router
     * @param route
     * @param auth
     * @param menuCtrl
     * @param platform
     * @param oAuthService
     * @param participantService
     * @param oAuthCordova
     * @param storage
     */
    constructor(
        private signInWithApple: SignInWithApple,
        public route: ActivatedRoute,
        public auth: AuthenticationService,
        public menuCtrl: MenuController,
        public platform: Platform,
        public oauthService: OAuthService,
        public participantService: ParticipantService,
        public userService: UserService,
        public oAuthCordova: OauthCordova,
        public storage: StorageService,
        //public pushPageService: PushPageService
    ) {
        super();

        // TODO?
        // if (!!this.nav.parent) {
        //     this.showHeader = true;
        // }

        if (this.plt.isConnected) {
            this.checkTokens();
        } else {
            // after restoring connection check tokens
            window.addEventListener('apponline', () => {
                this.checkTokens();
            }, { once: true });
        }
    }

    /**
     * on init
     *
     * @return void
     */
    ngOnInit() {
        this.plt.hideSidebar = this.hideSidebar;
        // NOTE[jg] - now solved in app.component
        // const locale = this.route.snapshot.queryParams['forceLocale'];
        // if (locale) {
        //     setTimeout(() => {
        //         this.translate.use(locale);
        //     }, 250);
        // }

        // get event key from url
        if (this.route.snapshot.params['event-key']) {
            this.eventKey = decodeURIComponent(this.route.snapshot.params['event-key']);
            this.showEventKey = true;
        }

        // check if there is request for different start url
        if (this.route.snapshot.queryParams['redirect']) {
            this.plt.saveStartUrl(decodeURIComponent(this.route.snapshot.queryParams['redirect']));
        }

        let lastUrl;

        this.router.events.pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe(
            (event: any) => {

                // for route event event get route event
                if ((event instanceof Scroll)) {
                    event = <RouterEvent>event.routerEvent;
                }

                // using url from NavigationEnd instance, because route.snapshot was not sometimes updated...
                if (event instanceof RouterEvent && event instanceof NavigationEnd) {
                    if (this.plt.isConnected && event.url != lastUrl) {
                        lastUrl = event.url;
                        this.verifyParams();
                    } else {
                        // after restoring connection check tokens for cordova also
                        window.addEventListener('apponline', () => {
                            this.verifyParams();
                        }, { once: true });
                    }
                }
            });

        // to be use later
        this.initSetting();

        // check linked in login CODE
        let urlParams = new URLSearchParams(window.location.search);
        let linkedInCode = urlParams.get('code');

        if (linkedInCode && urlParams.get('oauth') == 'linkedin') {

            this.showLoading();

            // be sure storage is loaded before firrst usage
            this.storage.init().then(() => {
                this.storage.get('invitation_token').then(
                    (invitation_token) => {

                        this.auth.socialLogin({
                            redirectUri: linkedInAuthConfig.redirectUri + '&forceLocale=' + this.translate.currentLang,
                            code: linkedInCode
                        }, 'linkedin', invitation_token, 'pwa').subscribe(
                            (success) => {
                                this.auth.loginFromToken(success.token, 'login', this.plt.forceEventId);

                                if (invitation_token) {
                                    // remove invitation token if set
                                    // this.storage.remove('invitation_token');
                                }

                                // reset force event id
                                this.plt.forceEventId = null;
                                this.hideLoading();
                            },
                            (error) => {

                                if (typeof error === 'string') {
                                    error = {
                                        message: this.translate.instant('ERROR_' + error.toUpperCase())
                                    };
                                }

                                if (error.message === 'Authentication Canceled') {
                                    error.message = this.translate.instant('ERROR_AUTH_CANCELED2');
                                }
                                // TODO, show permament toast
                                this.showError(error.message);
                                this.hideLoading();
                            }
                        );
                    });
            });
        }
    }

    public initSetting() { }

    /**
     * after view init
     *
     * @return void
     */
    ngAfterViewInit() { // vs ionViewDidLoad?
        this.loaded = true;
    }

    verifyParams() {
        let urlParams = new URLSearchParams(window.location.search);

        // follow invitation - token must be added to every possible request from here
        let invitation_token = urlParams.get('invitation_token'); //this.route.snapshot.queryParams['invitation_token'];

        if (!!invitation_token) {
            this.storage.set('invitation_token', invitation_token);
        }

        // confirm registration
        let activate_token = urlParams.get('activate_token'); //this.route.snapshot.queryParams['activate_token'];
        if (activate_token) {
            this.storage.remove('invitation_token');
            this.auth.registerConfirmation(activate_token, 'participant')
                .subscribe(
                    (success) => {
                        this.showSuccess(success.message);
                        this.user.email = success.email;

                        // remove token from URL
                        urlParams.delete('activate_token');
                        this.routerExtService.softNavigate(window.location.pathname + '?' + urlParams.toString(), true);
                    },
                    (error) => {
                        this.showError(error.error.message);
                    });
        } else {
            this.storage.get('invitation_token').then((invitation_token_storage) => {

                // check if there is token from storage and use it if not token from param
                if (!!invitation_token_storage) {
                    invitation_token = invitation_token_storage;
                }

                if (!!invitation_token) {
                    this.enableRegistration = true;
                    this.participantService.getByInvitationToken(invitation_token).subscribe((participant: Participant) => {
                        this.user.email = participant.email;
                    });
                }
            });
        }

        let email = urlParams.get('email');
        if (email) {
            this.user.email = email;
        }
    }

    /**
    * verify tokens from storage and query params
    *
    * @return void
    */
    public checkTokens() {
        const invitation_token = this.route.snapshot.queryParams['invitation_token'];
        this.storage.get('token').then((token) => {
            if (token) {
                // redirect to dashboard if user is logged in
                if (invitation_token) {
                    this.userService.attachParticipant(invitation_token).subscribe((user: User) => {
                        this.userService.setCurrentUser(user);
                        this.auth.loginFromToken(token);
                        this.router.navigate([this.plt.defaultLink + '/home']);
                    });
                } else {
                    this.auth.loginFromToken(token);
                    this.router.navigate([this.plt.defaultLink + '/home']);
                }
            }
        });
    }

    /**
     * register
     *
     * @return void
     */
    public registerEventKey() {
        //this.nav.push('RegisterPage');
        this.router.navigate(['/register']);
    }

    /**
     * lost password
     *
     * @return void
     */
    public lostPassword() {
        //this.nav.push('LostPasswordPage');
        this.router.navigate(['/lost-password']);
    }

    /**
     * login
     *
     * @return void
     */
    public login(skipEventKeyValidation: boolean = false) {

        this.validate = true;

        if (!this.submitted && this.form.valid) {

            // don't send prefilled event key if that section is closed
            if (!this.showEventKey && !skipEventKeyValidation) {
                this.eventKey = '';
            }

            this.showLoading();
            this.submitted = true;

            this.storage.get('invitation_token').then(
                (invitation_token) => {
                    this.auth.login(
                        this.user.email,
                        this.user.password,
                        this.eventKey,
                        this.plt.is('cordova') ? 'mobile' : 'pwa',
                        this.checkTransfer(),
                        invitation_token,
                        this.eventKey ? null : this.checkEventid,
                        this.user.external_secret,
                        this.user.external_secret_verification,
                        this.user.event_id,
                        this.user.group_id,
                        this.keepLoggedIn,
                        this.route.snapshot.queryParams['s'] || this.route.snapshot.queryParams['src']
                    )
                        .subscribe(
                            (success) => {

                                this.submitted = false;
                                this.validate = false;
                                if (success['show_join_modal']) {
                                    // show modal with group selection
                                    setTimeout(() => {
                                        this.hideLoading();
                                        this.showSelectEvent(success);
                                    }, 500);
                                } else {
                                    this.user.email = '';
                                    this.user.password = '';
                                    this.eventKey = '';
                                    this.form.reset();
                                }

                                if (success['invalid_external_secret']) {
                                    this.storage.remove('external_secret');
                                }

                                // login successful if there's a jwt token in the response
                                const token = success.token as string;

                                if (token) {
                                    this.auth.loginFromToken(token, 'login', this.plt.forceEventId);
                                    // reset force event id
                                    this.plt.forceEventId = null;
                                    this.storage.remove('invitation_token');
                                }
                            },
                            (error) => {
                                this.hideLoading();
                                this.submitted = false;

                                if (!error.error || !error.status) {
                                    this.overlayService.showConnectionProblems(error);
                                    return;
                                }

                                // was a provide flag submitted?
                                const provide = error.error.provide;
                                if (provide && provide === 'reconfirmation') {
                                    // provide the user a reconfirmation option
                                    this.enableReconfirmation = true;
                                }

                                // remove invalid external token if sent in request
                                if (error.error.message == 'auth.invalid_external_secret') {
                                    this.storage.remove('external_secret');
                                    this.user.external_secret = null;
                                }

                                this.storage.get('invitation_token').then((invitation_token) => {
                                    if (invitation_token) {
                                        this.showConfirm(this.translate.instant('MESSAGE_WARNING'), error.error.message, () => { }, () => {
                                            this.storage.remove('invitation_token');
                                        });
                                    } else {
                                        this.showWarning(error.error.message, 'public');
                                    }
                                });
                            });
                })
        }
    }

    /**
     * open group select modal
     *
     * @return void
     */
    public async showSelectEvent(success) {

        this.routerExtService.softNavigate();

        success.groups.forEach((group, i) => {
            this.groupKeys.forEach((groupKey) => {
                if (groupKey.id == group.id) {
                    success.groups[i].event_key = groupKey.event_key;
                }
            });
        });

        success.groups = success.groups.filter((group) => { return this.hiddenGroups.indexOf(group.id) == -1 });

        let groups = [];

        if (this.groupOrder.length > 0) {
            this.groupOrder.forEach((id) => {
                success.groups.forEach((group) => {
                    if (group.id == id) {
                        groups.push(group)
                    }
                });
            })
        } else {
            groups = success.groups;
        }

        const modal = await this.modalController.create({
            component: SelectGroupForJoinComponent,
            cssClass: 'group-select-modal',
            componentProps: {
                groups: groups
            }
        });

        modal.onWillDismiss().then((data) => {
            if (data.data) {

                if (data.data.action && data.data.action == 'close') {
                    this.routerExtService.softBack();
                }

                if (data.data.selectedGroup) {
                    // continue with login with event_key
                    this.eventKey = data.data.selectedGroup.event_key;
                    this.login(true);
                }
            }
        });

        return await modal.present();
    }

    /**
     * Resend confirmation email
     *
     * @return void
     */
    public resendConfirmation() {
        this.reconfirmation = true;

        this.auth.resendConfirmation(this.user.email, 'participant', this.plt.is('cordova') ? 'mobile' : 'pwa')
            .subscribe(
                (success) => {
                    this.hideLoading();
                    this.overlayService.showConfirmInfo(this.translate.instant('MESSAGE_SUCCESS'), success.message);
                    this.reconfirmation = false;
                    this.enableReconfirmation = true;
                },
                (error) => {
                    this.hideLoading();
                    if (!error.error || !error.status) {
                        this.overlayService.showConnectionProblems(error);
                        return;
                    }

                    this.showError(error.error.message);
                    this.reconfirmation = false;
                    this.enableReconfirmation = true;
                });
    }

    /**
     * applo sign in
     *
     * @return void
     */
    public appleLogin() {
        this.signInWithApple.signin({
            requestedScopes: [
                ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
                ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
            ]
        })
            .then((res: AppleSignInResponse) => {
                // https://developer.apple.com/documentation/signinwithapplerestapi/verifying_a_user
                this.storage.get('invitation_token').then(
                    (invitation_token) => {
                        this.auth.appleLogin(res, invitation_token).subscribe(
                            (success) => {
                                // login successful if there's a jwt token in the response
                                const token = success.token as string;

                                if (token) {
                                    this.auth.loginFromToken(token, 'login', this.plt.forceEventId);
                                    // reset force event id
                                    this.plt.forceEventId = null;
                                    this.storage.remove('invitation_token');
                                }
                            },
                            (error) => {
                                this.hideLoading();
                                try {
                                    this.showError(error.error.message);
                                }
                                catch (e) {
                                    this.showError();
                                }
                            }
                        );
                    });
            })
            .catch((error: AppleSignInErrorResponse) => {
                // alert(error.code + ' ' + error.localizedDescription);
                console.error(error);
                this.showWarning(this.translate.instant('ERROR_AUTH_CANCELED'));
            });
    }

    /**
     * login via linkedin
     *
     * @return void
     */
    public socialLoginWeb(type: string) {

        // this.showLoading();

        if (this.platform.is('cordova')) {
            this.showLoading();
            this.oAuthCordova.logInVia(this.linkedinProvider, {
                // clearsessioncache: 'no',
                // toolbarposition: 'top',
                // hideurlbar:'no',
                // beforeload: 'get'
            }).then(
                (success) => {
                    this.storage.get('invitation_token').then(
                        (invitation_token) => {
                            let payload = { ...success, redirectUri: environment.redirectUri };
                            this.auth.socialLogin(payload, type, invitation_token).subscribe(
                                (success) => {
                                    // login successful if there's a jwt token in the response
                                    const token = success.token as string;

                                    if (token) {
                                        this.auth.loginFromToken(token, 'login', this.plt.forceEventId);
                                        // reset force event id
                                        this.plt.forceEventId = null;
                                        this.storage.remove('invitation_token');
                                    }
                                },
                                (error) => {
                                    this.hideLoading();
                                    try {
                                        this.showError(error.error.message);
                                    }
                                    catch (e) {
                                        this.showError();
                                    }
                                }
                            );
                        });
                }, (e) => {
                    console.log(e);
                    this.showWarning(this.translate.instant('ERROR_AUTH_CANCELED'));
                });
        } else {
            this.showLoading();
            // trigger linkedin login
            let payload = linkedInAuthConfig;
            payload.redirectUri += '&forceLocale=' + this.translate.currentLang;
            this.oauthService.configure(payload);
            this.oauthService.initLoginFlow();
            // this.oauthService.initLoginFlowInPopup();
        }
    }

    private checkTransfer(): number {
        // verify transfer accepted params
        let urlParams = new URLSearchParams(window.location.search);
        let transferAcceptedEventId: number = 0;
        let email = urlParams.get('email');
        // if mail uses for login is same as from link
        if (email == this.user.email) {
            // check transfer accepted params
            if (urlParams.get('transfer_accepted_event_id')) {
                // store info with login that user has accepted the transfer
                transferAcceptedEventId = parseInt(urlParams.get('transfer_accepted_event_id'))
            }
        }

        return transferAcceptedEventId;
    }

    /**
     * set language
     */
    public switchLanguage(lang) {
        // reset form validation if fields are empty
        if (this.plt.isConnected && !this.user.email && !this.user.password) {
            this.form.reset();
            this.emailInput.setFocus();
        }
        this.translate.use(lang);
        this.userLang = lang;
    }

    /**
     * open event detail popup
     *
     * @return void
     */
    public async showQRCodeReader() {

        this.routerExtService.softNavigate();

        const modal = await this.modalController.create({
            component: QrCodeReaderComponent,
            cssClass: 'qr-scan',
            componentProps: {

            }
        });

        modal.onWillDismiss().then((data) => {
            if (data.data) {
                if (data.data.action && data.data.action == 'close') {
                    this.routerExtService.softBack();
                }

                if (data.data.text) {
                    this.eventKey = data.data.text;
                }
            }

        });

        return await modal.present();

    }

    /**
     * on destroy
     */
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
