<ion-header class="ion-no-shadow ion-no-border ion-hide-xl-up">
    <ion-toolbar>
        <ng-container>
            <ion-title>
                {{ 'MARKETPLACE_POST_DESIGN_DETAIL' | translate }}
            </ion-title>
            <ion-buttons slot="end">
                <ion-button (click)="backClicked()">
                    <ion-icon slot="icon-only"
                              color="dark"
                              name="close">
                    </ion-icon>
                </ion-button>
            </ion-buttons>
        </ng-container>
    </ion-toolbar>
</ion-header>

<div class="design-card"
     [ngClass]="{'edit-mode': plt.sizeSm && (!event?.disableInteraction || editable)}">
    <div class="design-menu">

        <div class="design-menu-content"
             [ngClass]="{'preview' : isPreview}">
            <div class="category-label">
                <ng-container *ngIf="marketplacePost.marketplace_category_id">{{ categoryName }}</ng-container>
                <ng-container *ngIf="!marketplacePost.marketplace_category_id">{{ 'MARKETPLACE_POST_WITHOUT_CATEGORY' | translate }}</ng-container>
            </div>

            <div class="info">
                <div *ngIf="marketplacePost.image_url"
                     class="image-preview">
                    <img [src]="plt.createSignedLink(marketplacePost.image_url, false, true) | fullImage">
                </div>

                <h2>
                    <span>{{ marketplacePost.name }}</span>
                </h2>

                <div class="action-buttons">

                    <a *ngIf="marketplacePost.phone"
                       [href]="'tel:' + marketplacePost.phone">
                        <svg-icon slot="start"
                                  [applyClass]="true"
                                  src="/assets/icons/ico-phone.svg">
                        </svg-icon> {{ marketplacePost.phone }}
                    </a>

                    <ng-container *ngIf="!plt.sizeSm && !event?.disableInteraction">
                        <ion-button *ngIf="!user?.selected_participant.invisible"
                                    (click)="toggleLike(marketplacePost)"
                                    color="white"
                                    class="full">
                            <svg-icon slot="start"
                                      [applyClass]="true"
                                      src="/assets/icons/ico-like.svg"
                                      [ngClass]="{'active': isLiked(marketplacePost)}"
                                      class="like">
                            </svg-icon> {{ 'MARKETPLACE_POST_LIKE' | trns }}
                            <ion-spinner *ngIf="likeLoading"
                                         name="dots">
                            </ion-spinner>
                        </ion-button>

                        <ion-button (click)="openComments()"
                                    color="white"
                                    class="full">
                            <svg-icon slot="start"
                                      [applyClass]="true"
                                      src="/assets/icons/ico-message.svg"
                                      class="like">
                            </svg-icon> {{ 'MARKETPLACE_POST_COMMENTS' | trns }}
                        </ion-button>
                    </ng-container>

                    <ion-button *ngIf="user?.canMessage(marketplacePost.getRealParticipant()) && !user?.selected_participant?.not_contactable && user?.selected_participant?.id != marketplacePost.getRealParticipant().id"
                                (click)="goToMessages(marketplacePost)"
                                color="white"
                                class="full">
                        <svg-icon slot="start"
                                  [applyClass]="true"
                                  src="/assets/icons/ico-message.svg">
                        </svg-icon> {{ 'ATTENDEE_DETAIL_MESSAGE' | translate }}
                    </ion-button>

                    <a *ngIf="marketplacePost.email"
                       [href]="'mailto:' + marketplacePost.email + '?subject=' + addReferenceSubject(marketplacePost.name)">
                        <svg-icon slot="start"
                                  [applyClass]="true"
                                  src="/assets/icons/ico-mail.svg">
                        </svg-icon> {{ marketplacePost.email }}
                    </a>

                    <ng-container *ngFor="let group of marketplacePost.groupedHeadlineDetails">
                        <ng-container *ngIf="!group.lang || group.lang == userLang">
                            <ng-container *ngFor="let detail of group.details">
                                <ng-container *ngIf="detail.type == 'link'">
                                    <a (click)="openLink(detail.value, detail.options.target)"
                                       class="pointer">
                                        <ng-container *ngIf="detail.options.name">{{ detail.options.name }}</ng-container>
                                        <ng-container *ngIf="!detail.options.name">{{ detail.value }}</ng-container>
                                    </a>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </div>

            <div *ngIf="!isPreview"
                 class="contacts-form">
                <div class="details ion-no-padding">
                    <div class="detail-types">
                        <div (click)="addContactsHeadline()"
                             class="detail-type">
                            <svg-icon [applyClass]="true"
                                      [class]="text"
                                      [src]="'/assets/icons/ico-attendee.svg'">
                            </svg-icon>
                            <div>
                                <h3>{{ 'MARKETPLACE_POST_CONTACTS_ADD_HEADLINE' | translate }}</h3>
                                <p>{{ 'MARKETPLACE_POST_CONTACTS_ADD_HEADLINE_HINT' | translate }}</p>
                            </div>
                        </div>
                    </div>

                    <div *ngFor="let headline of marketplacePost.contacts; let i = index">
                        <ion-row class="ion-no-padding"
                                 [ngClass]="{ 'has-error': validate && validationErrors['contacts.'+i+'.title'] }">
                            <ion-col size="10"
                                     class="ion-no-padding">
                                <ion-item class="item-title">
                                    <ion-input type="text"
                                               [name]="'headline-' + i + '-title'"
                                               (ionFocus)="plt.checkResize(false, $event, !wizard ? 'nearest' : 'center') "
                                               (ionBlur)="plt.checkResize(false)"
                                               placeholder="{{ 'MARKETPLACE_POST_CONTACTS_TITLE' | translate }}"
                                               inputmode="text"
                                               spellcheck="false"
                                               autocapitalize="off"
                                               [(ngModel)]="headline.title">
                                    </ion-input>
                                </ion-item>
                            </ion-col>
                            <ion-col size="1"
                                     class="ion-no-padding">
                                <svg-icon [applyClass]="true"
                                          src="/assets/icons/ico-close.svg"
                                          class="close pointer"
                                          (click)="removeContactsHeadline(headline)">
                                </svg-icon>
                            </ion-col>
                        </ion-row>
                        <ion-row class="ion-no-padding error"
                                 *ngIf="validate && validationErrors['contacts.'+i+'.title']">
                            <ion-col class="ion-no-padding">
                                <span class="error-message">
                                    {{ validationErrors['contacts.'+i+'.title'] }}
                                </span>
                            </ion-col>
                        </ion-row>

                        <ion-row class="ion-no-padding contact-items">
                            <ion-col class="ion-no-padding">
                                <div *ngFor="let participant of headline.participants"
                                     class="contact-item">
                                    {{participant.firstname}} {{participant.lastname}}
                                    <svg-icon (click)="removeContact(participant, headline)"
                                              [applyClass]="true"
                                              src="/assets/icons/ico-close.svg"
                                              class="icon fill-dark">
                                    </svg-icon>
                                </div>
                                <div *ngIf="headline != activeHeadline"
                                     class="contact-item add">
                                    <svg-icon (click)="setActiveHeadline(headline)"
                                              [applyClass]="true"
                                              src="/assets/icons/ico-attendee.svg"
                                              class="icon fill-dark">
                                    </svg-icon>
                                    {{ 'MARKETPLACE_POST_CONTACTS_ADD' | translate }}
                                </div>
                            </ion-col>
                        </ion-row>

                        <ion-row *ngIf="headline == activeHeadline"
                                 class="ion-no-padding">
                            <ion-col class="ion-no-padding">
                                <ion-item id="contact-box"
                                          class="contacts"
                                          [ngClass]="{ 'has-error': validate && validationErrors.participants }">
                                    <ng-template #emptyTemplate
                                                 let-attrs="attrs"
                                                 class="ion-text-center">
                                        {{ 'APPOINTMENT_MEMBERS_NO_ITEMS' | translate }}
                                    </ng-template>
                                    <ng-template #participantTemplate
                                                 let-attrs="attrs">
                                        {{attrs.data.firstname}} {{attrs.data.lastname}}
                                    </ng-template>
                                    <ion-auto-complete #autocomplete
                                                       [maxResults]="6"
                                                       [dataProvider]="participantService"
                                                       [dataProviderAttribute]="'participants'"
                                                       [exclude]="[].concat(activeHeadline.participants)"
                                                       [template]="participantTemplate"
                                                       [selectionTemplate]="participantTemplate"
                                                       [emptyTemplate]="emptyTemplate"
                                                       [options]="{mode: 'ios', placeholder: 'MARKETPLACE_CONTACTS_LIST_SEARCH_PLACEHOLDER' | translate }"
                                                       (ionAutoInput)="autocompleteFocus()"
                                                       (itemsShown)="autocompleteFocus()"
                                                       (itemsHidden)="autocompleteBlur()"
                                                       (autoBlur)="autocompleteBlur()"
                                                       (itemSelected)="addContact($event)">
                                    </ion-auto-complete>
                                </ion-item>
                            </ion-col>
                        </ion-row>

                        <hr *ngIf="(i + 1) < marketplacePost.contacts.length"
                            class="separator" />

                    </div>

                    <div class="detail-types">

                        <div (click)="addDetail({name: 'link', group: ['link']}, {headline: true})"
                             class="detail-type">
                            <svg-icon [applyClass]="true"
                                      [class]="text"
                                      [src]="'/assets/icons/profile/link.svg'">
                            </svg-icon>
                            <div>
                                <h3>{{ 'MARKETPLACE_POST_LINK_ADD_HEADLINE' | translate }}</h3>
                                <p>{{ 'MARKETPLACE_POST_LINK_ADD_HEADLINE_HINT' | translate }}</p>
                            </div>
                        </div>

                    </div>

                    <ng-container *ngFor="let group of marketplacePost.groupedHeadlineDetails ; let groupIndex = index">
                        <br />
                        <strong *ngIf="'PARTICIPANT_DETAIL_HEADER_TYPE_' + group.type | checkTranslate">{{ 'PARTICIPANT_DETAIL_HEADER_TYPE_' + group.type | checkTranslate }}</strong>
                        <ion-icon (click)="deleteGroup(groupIndex, group, true)"
                                  color="dark"
                                  class="pointer close headline"
                                  name="close">
                        </ion-icon>
                        <ion-row class="ion-no-padding headline-item">
                            <ng-container *ngFor="let detail of group.details; let detailIndex = index">
                                <ion-col size="12"
                                         class="ion-no-padding"
                                         [class]="detail.type">
                                    <ion-item
                                              [ngClass]="{ 'has-error': validate && validationErrors['groupedDetails.' + (groupIndex + marketplacePost.groupedDetails.length) + '.details.' + detailIndex + '.value'] }">
                                        <ion-label *ngIf="'PARTICIPANT_DETAIL_LABEL_TYPE_' + detail.type | checkTranslate"
                                                   position="stacked">
                                            {{ 'PARTICIPANT_DETAIL_LABEL_TYPE_' + detail.type | translate }}
                                        </ion-label>
                                        <ng-container [ngSwitch]="true">

                                            <ng-container *ngSwitchDefault>
                                                <ion-label *ngIf="plt.sizeSm && !('PARTICIPANT_DETAIL_LABEL_TYPE_' + detail.type | checkTranslate)"
                                                           position="stacked">
                                                    &nbsp;
                                                    <!-- empty label to have fixed space -->
                                                </ion-label>
                                                <ion-input type="text"
                                                           [name]="'detail' + (groupIndex + marketplacePost.groupedDetails.length) + '-' + detailIndex"
                                                           (ionFocus)="plt.checkResize(false, $event, !wizard ? 'nearest' : 'center') "
                                                           (ionBlur)="plt.checkResize(false)"
                                                           placeholder="{{ 'PARTICIPANT_DETAIL_DESC_TYPE_' + detail.type | checkTranslate }}"
                                                           inputmode="text"
                                                           spellcheck="false"
                                                           autocapitalize="off"
                                                           [(ngModel)]="detail.value">
                                                </ion-input>
                                            </ng-container>

                                        </ng-container>
                                    </ion-item>

                                    <span *ngIf="!detail.error"
                                          class="hint">{{ 'PARTICIPANT_DETAIL_HINT_TYPE_' + detail.type | checkTranslate }}</span>
                                    <span *ngIf="validate && detail.error"
                                          class="error-message">
                                        {{ detail.error }}
                                    </span>
                                    <span *ngIf="validate && validationErrors['groupedDetails.' + (groupIndex + marketplacePost.groupedDetails.length) + '.details.' + detailIndex + '.value']"
                                          class="error-message">
                                        {{ validationErrors['groupedDetails.' + (groupIndex + marketplacePost.groupedDetails.length) + '.details.' + detailIndex + '.value'] }}
                                    </span>

                                </ion-col>
                                <!-- Special option to add name for link -->
                                <ng-container *ngIf="detail.type == 'link'">
                                    <ion-col size="12"
                                             class="ion-no-padding">
                                        <ion-item
                                                  [ngClass]="{ 'has-error': validate && validationErrors['groupedDetails.' + (groupIndex + marketplacePost.groupedDetails.length) + '.details.' + detailIndex + '.options.name'] }">
                                            <ion-label *ngIf="detail.type!='image' && detail.type!='paragraph'"
                                                       position="stacked">
                                                {{ 'PARTICIPANT_DETAIL_TYPE_' + detail.type + '_name' | translate }}
                                            </ion-label>
                                            <ion-input type="text"
                                                       [name]="'detail' + (groupIndex + marketplacePost.groupedDetails.length) + '-1-' + detailIndex"
                                                       (ionFocus)="plt.checkResize(false, $event, !wizard ? 'nearest' : 'center') "
                                                       (ionBlur)="plt.checkResize(false)"
                                                       placeholder="{{ 'PARTICIPANT_DETAIL_DESC_TYPE_' + detail.type + '_name' | checkTranslate }}"
                                                       inputmode="text"
                                                       spellcheck="false"
                                                       autocapitalize="off"
                                                       [(ngModel)]="detail.options.name">
                                            </ion-input>
                                        </ion-item>
                                        <span *ngIf="!validationErrors['groupedDetails.' + (groupIndex + marketplacePost.groupedDetails.length) + '.details.' + detailIndex + '.options.name']"
                                              class="hint">{{ 'PARTICIPANT_DETAIL_HINT_TYPE_' + detail.type + '_name' | checkTranslate }}</span>
                                        <span *ngIf="validate && validationErrors['groupedDetails.' + (groupIndex + marketplacePost.groupedDetails.length) + '.details.' + detailIndex + '.options.name'] && detail.options?.error"
                                              class="error-message">
                                            {{ detail.options.error }}
                                        </span>
                                    </ion-col>
                                </ng-container>

                            </ng-container>
                        </ion-row>
                    </ng-container>

                </div>
            </div>

            <div *ngIf="isPreview && marketplacePost.contacts.length > 0"
                 class="contacts-wrapper">
                <div *ngFor="let headline of marketplacePost.contacts; let i = index">
                    <h4>{{ headline.title }}</h4>

                    <div *ngFor="let participant of headline.participants; let pi = index"
                         class="contact-item">
                        <attendee-list-item [attendee]="participant"
                                            [viewType]="'lines'"
                                            [type]="'marketplace-contacts'"
                                            [isPreview]="editable"
                                            [parentObject]="marketplacePost"
                                            (onAttendeeDetail)="showAttendeeDetail($event)"
                                            (onRemoveMyContact)="removeMyContact(headline, $event)">
                        </attendee-list-item>

                        <hr *ngIf="(pi + 1) < headline.participants.length"
                            class="separator" />
                    </div>

                </div>
            </div>
        </div>

        <div *ngIf="(user?.id == marketplacePost.participant?.user_id || !marketplacePost.id) && !plt.sizeSm"
             class="control-buttons">


            <ion-button *ngIf="isPreview && editable"
                        color="primary"
                        expand="block"
                        (click)="togglePreview()">
                {{ 'MARKETPLACE_POST_DESIGN' | translate }}
            </ion-button>

            <ng-container *ngIf="!isPreview">
                <ion-button color="primary"
                            expand="block"
                            (click)="togglePreview()">
                    {{ 'BUTTON_SHOW_PREVIEW' | translate }}
                </ion-button>
            </ng-container>

            <ion-button *ngIf="editable"
                        color="primary"
                        expand="block"
                        [disabled]="sending || uploading || loading"
                        (click)="closeSaved()">
                {{ 'BUTTON_SAVE' | translate }}
            </ion-button>

            <ng-container *ngIf="!isPreview">
                <ion-button class="btn-close"
                            expand="block"
                            (click)="backClicked()">
                    {{ 'BUTTON_CANCEL' | translate }}
                </ion-button>
            </ng-container>

        </div>
    </div>

    <div class="design-detail">
        <ion-grid *ngIf="!isPreview"
                  class="details ion-no-padding">
            <!-- detail list -->
            <ng-container *ngFor="let group of marketplacePost.groupedDetails ; let groupIndex = index">
                <ng-container *ngIf="!group.lang || group.lang == userLang">
                    <div *ngIf="plt.sizeSm"
                         class="actions {{group.type}}">
                        <ion-icon *ngIf="groupIndex > 0"
                                  (click)="moveDetailUp(groupIndex)"
                                  color="dark"
                                  class="pointer"
                                  name="arrow-up">
                        </ion-icon>
                        <ion-icon *ngIf="groupIndex < (marketplacePost.groupedDetails.length - 1)"
                                  (click)="moveDetailDown(groupIndex)"
                                  color="dark"
                                  class="pointer"
                                  name="arrow-down">
                        </ion-icon>
                        <ion-icon (click)="deleteGroup(groupIndex, group)"
                                  color="dark"
                                  class="pointer close"
                                  name="close">
                        </ion-icon>
                    </div>
                    <ion-row class="ion-no-padding">
                        <ion-col class="ion-no-padding">
                            <h3 *ngIf="'PARTICIPANT_DETAIL_HEADER_TYPE_' + group.type | checkTranslate">{{ 'PARTICIPANT_DETAIL_HEADER_TYPE_' + group.type | checkTranslate }}</h3>
                            <ion-row class="ion-no-padding">
                                <ng-container *ngFor="let detail of group.details; let detailIndex = index">
                                    <ion-col [class]="detail.type">
                                        <ion-item [ngClass]="{ 'has-error': validate && validationErrors['groupedDetails.' + groupIndex + '.details.' + detailIndex + '.value'] }">
                                            <ion-label *ngIf="'PARTICIPANT_DETAIL_LABEL_TYPE_' + detail.type | checkTranslate"
                                                       position="stacked">
                                                {{ 'PARTICIPANT_DETAIL_LABEL_TYPE_' + detail.type | translate }}
                                            </ion-label>
                                            <ng-container [ngSwitch]="true">

                                                <ng-container *ngSwitchCase="detail.type == 'image'">
                                                    <ion-thumbnail slot="start">
                                                        <ng-container *ngIf="detail.value">
                                                            <ng-container *ngIf="detail.options?.type == 'pdf'">
                                                                <a [href]="detail.value"
                                                                   onclick="window.open(this.href, '_system');return false">
                                                                    <svg-icon [applyClass]="true"
                                                                              [src]="'/assets/icons/profile/pdf.svg'">
                                                                    </svg-icon>
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *ngIf="detail.options?.type != 'pdf'">
                                                                <img [src]="plt.createSignedLink(detail.value.replace('_preview',''))">
                                                            </ng-container>
                                                            <div class="remove"
                                                                 (click)="detail.value = ''"></div>
                                                        </ng-container>
                                                        <ng-container *ngIf="!detail.value">
                                                            <ng-container *ngIf="!plt.is('cordova')">
                                                                <dropzone [message]="''"
                                                                          [config]="dropZoneConfigExtended"
                                                                          (success)="onUploadSuccess($event, detail)"
                                                                          (error)="onUploadError($event)"
                                                                          (sending)="onSending($event)">
                                                                </dropzone>
                                                            </ng-container>
                                                            <ng-container *ngIf="plt.is('cordova')">

                                                                <div *ngIf="!detail.uploading"
                                                                     class="image-actions">
                                                                    <button type="button"
                                                                            class="pure-btn"
                                                                            (click)="openCamera(detail)">
                                                                        <ion-icon name="camera"
                                                                                  color="light-blue"></ion-icon>
                                                                    </button>
                                                                    <button type="button"
                                                                            class="pure-btn"
                                                                            (click)="openAlbums(detail)">
                                                                        <ion-icon name="image"
                                                                                  color="light-blue"></ion-icon>
                                                                    </button>
                                                                    <ng-container>
                                                                        <dropzone [message]="''"
                                                                                  [config]="dropZoneConfigExtended"
                                                                                  (success)="onUploadSuccess($event, detail)"
                                                                                  (error)="onUploadError($event)"
                                                                                  (sending)="onSending($event)">
                                                                        </dropzone>
                                                                    </ng-container>
                                                                </div>

                                                                <div *ngIf="detail.uploading"
                                                                     class="image-upload">
                                                                    <div class="uploading-spinner">
                                                                        <ion-spinner></ion-spinner>
                                                                    </div>
                                                                    <!-- END :: initial -->
                                                                    <div class="progress-container">
                                                                        <progress class="progress"
                                                                                  max="100"
                                                                                  value="{{ detail.progress }}">
                                                                        </progress>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                            <div class="remove"
                                                                 (click)="deleteDetail(detailIndex, detail, groupIndex, group)">
                                                            </div>
                                                        </ng-container>
                                                    </ion-thumbnail>
                                                </ng-container>

                                                <ng-container *ngSwitchCase="detail.type == 'paragraph'">

                                                    <ion-textarea placeholder="{{ 'MARKETPLACE_DETAIL_PLACEHOLDER_TYPE_' + detail.type | checkTranslate }}"
                                                                  [name]="'detail' + groupIndex + '-' + detailIndex"
                                                                  ieTextareaFix
                                                                  inputmode="text"
                                                                  spellcheck="false"
                                                                  autocapitalize="off"
                                                                  [(ngModel)]="detail.value">
                                                    </ion-textarea><br />
                                                </ng-container>

                                                <ng-container *ngSwitchDefault>
                                                    <ion-label *ngIf="plt.sizeSm && !('PARTICIPANT_DETAIL_LABEL_TYPE_' + detail.type | checkTranslate)"
                                                               position="stacked">
                                                        &nbsp;
                                                        <!-- empty label to have fixed space -->
                                                    </ion-label>
                                                    <ion-input type="text"
                                                               [name]="'detail' + groupIndex + '-' + detailIndex"
                                                               (ionFocus)="plt.checkResize(false, $event, !wizard ? 'nearest' : 'center') "
                                                               (ionBlur)="plt.checkResize(false)"
                                                               placeholder="{{ 'PARTICIPANT_DETAIL_DESC_TYPE_' + detail.type | checkTranslate }}"
                                                               inputmode="text"
                                                               spellcheck="false"
                                                               autocapitalize="off"
                                                               [(ngModel)]="detail.value">
                                                    </ion-input>
                                                </ng-container>

                                            </ng-container>
                                        </ion-item>
                                        <span *ngIf="!detail.error"
                                              class="hint">{{ 'PARTICIPANT_DETAIL_HINT_TYPE_' + detail.type | checkTranslate }}</span>
                                        <span *ngIf="validate && detail.error"
                                              class="error-message">
                                            {{ detail.error }}
                                        </span>
                                        <span *ngIf="validate && validationErrors['groupedDetails.' + groupIndex + '.details.' + detailIndex + '.value']"
                                              class="error-message">
                                            {{ validationErrors['groupedDetails.' + groupIndex + '.details.' + detailIndex + '.value'] }}
                                        </span>

                                        <!-- allow to insert some description for files -->
                                        <ng-container *ngIf="detail.type == 'image'">
                                            <ion-item [ngClass]="{ 'has-error': validate && validationErrors['groupedDetails.' + groupIndex + '.details.' + detailIndex + '.options.name'] }">
                                                <ion-input type="text"
                                                           [name]="'detail' + groupIndex + '-1-' + detailIndex"
                                                           (ionFocus)="plt.checkResize(false, $event, !wizard ? 'nearest' : 'center') "
                                                           (ionBlur)="plt.checkResize(false)"
                                                           placeholder="{{ 'PARTICIPANT_DETAIL_DESC_TYPE_' + detail.type + '_name' | checkTranslate }}"
                                                           inputmode="text"
                                                           spellcheck="false"
                                                           autocapitalize="off"
                                                           [(ngModel)]="detail.options.name">
                                                </ion-input>
                                            </ion-item>
                                            <span *ngIf="validate && validationErrors['groupedDetails.' + groupIndex + '.details.' + detailIndex + '.options.name']"
                                                  class="error-message">
                                                {{ validationErrors['groupedDetails.' + groupIndex + '.details.' + detailIndex + '.options.name'] }}
                                            </span>
                                        </ng-container>

                                    </ion-col>
                                    <!-- Special option to add name for link -->
                                    <ng-container *ngIf="detail.type == 'link'">
                                        <ion-col>
                                            <ion-item [ngClass]="{ 'has-error': validate && validationErrors['groupedDetails.' + groupIndex + '.details.' + detailIndex + '.options.name'] }">
                                                <ion-label *ngIf="detail.type!='image' && detail.type!='paragraph'"
                                                           position="stacked">
                                                    {{ 'PARTICIPANT_DETAIL_TYPE_' + detail.type + '_name' | translate }}
                                                </ion-label>
                                                <ion-input type="text"
                                                           [name]="'detail' + groupIndex + '-1-' + detailIndex"
                                                           (ionFocus)="plt.checkResize(false, $event, !wizard ? 'nearest' : 'center') "
                                                           (ionBlur)="plt.checkResize(false)"
                                                           placeholder="{{ 'PARTICIPANT_DETAIL_DESC_TYPE_' + detail.type + '_name' | checkTranslate }}"
                                                           inputmode="text"
                                                           spellcheck="false"
                                                           autocapitalize="off"
                                                           [(ngModel)]="detail.options.name">
                                                </ion-input>
                                            </ion-item>
                                            <span *ngIf="!validationErrors['groupedDetails.' + groupIndex + '.details.' + detailIndex + '.options.name']"
                                                  class="hint">{{ 'PARTICIPANT_DETAIL_HINT_TYPE_' + detail.type + '_name' | checkTranslate }}</span>
                                            <span *ngIf="validate && validationErrors['groupedDetails.' + groupIndex + '.details.' + detailIndex + '.options.name'] && detail.options?.error"
                                                  class="error-message">
                                                {{ detail.options.error }}
                                            </span>
                                        </ion-col>
                                    </ng-container>

                                </ng-container>
                                <ng-container *ngIf="(group.type == 'image') && group.details.length < 4">
                                    <ion-col>
                                        <ion-item>
                                            <ion-icon (click)="addGroupDetailBefore(group,'image')"
                                                      color="light"
                                                      class="pointer"
                                                      name="add-circle">
                                            </ion-icon>
                                        </ion-item>
                                    </ion-col>
                                </ng-container>
                            </ion-row>
                            <!-- buttons to add image before/after paragraph -->
                            <ng-container *ngIf="(group.type == 'paragraph') && group.details.length < 3">
                                <ion-row class="extend-paragraph ion-no-padding">
                                    <ion-col class="ion-no-padding">
                                        <ion-item (click)="addGroupDetail(group,'image')"
                                                  class="pointer">
                                            <ion-icon color="light"
                                                      name="add-circle">
                                            </ion-icon>
                                            {{ 'PARTICIPANT_DETAIL_ADD_IMAGE_BEFORE' | translate }}
                                        </ion-item>
                                    </ion-col>
                                    <ion-col class="right ion-no-padding">
                                        <ion-item (click)="addGroupDetailBefore(group,'image')"
                                                  class="pointer">
                                            {{ 'PARTICIPANT_DETAIL_ADD_IMAGE_AFTER' | translate }}
                                            <ion-icon color="light"
                                                      name="add-circle">
                                            </ion-icon>
                                        </ion-item>
                                    </ion-col>
                                </ion-row>
                            </ng-container>
                        </ion-col>

                        <ion-col *ngIf="!plt.sizeSm"
                                 class="actions">
                            <ion-icon *ngIf="groupIndex < (marketplacePost.groupedDetails.length - 1)"
                                      (click)="moveDetailDown(groupIndex)"
                                      color="dark"
                                      class="pointer"
                                      name="arrow-down">
                            </ion-icon>
                            <ion-icon *ngIf="groupIndex > 0"
                                      (click)="moveDetailUp(groupIndex)"
                                      color="dark"
                                      class="pointer"
                                      name="arrow-up">
                            </ion-icon>
                            <ion-icon (click)="deleteGroup(groupIndex, group)"
                                      color="dark"
                                      class="pointer close"
                                      name="close">
                            </ion-icon>
                        </ion-col>
                    </ion-row>
                    <ng-container *ngIf="groupIndex < (marketplacePost.groupedDetails.length - 1)">
                        <hr class="separator {{ group.type }}"
                            [ngClass]="{'enabled':group.line}" />
                        <ion-item class="set-line ion-no-padding">
                            <ion-checkbox slot="start"
                                          [name]="'group' + groupIndex + '-1-line'"
                                          [(ngModel)]="group.line">
                            </ion-checkbox>
                            <ion-label>{{ 'PARTICIPANT_DETAIL_SHOW_LINE' | translate }}</ion-label>
                        </ion-item>
                    </ng-container>
                </ng-container>
            </ng-container>

            <hr />

            <!-- detail types form -->
            <div (click)="addDetail({name: 'header', group: ['header']})"
                 class="detail-type-header">
                <h3>{{ 'PARTICIPANT_DETAIL_TYPE_header' | translate }}</h3>
                <ion-icon color="light"
                          name="add-circle">
                </ion-icon>
            </div>
            <ion-row class="detail-types">
                <ng-container *ngFor="let type of marketplacePost.detailTypes">
                    <ion-col size="6">
                        <div (click)="addDetail(type)"
                             class="detail-type">
                            <svg-icon [applyClass]="true"
                                      *ngIf="type.icon"
                                      [class]="type.icon"
                                      [src]="'/assets/icons/profile/' + type.icon + '.svg'">
                            </svg-icon>
                            <svg-icon [applyClass]="true"
                                      *ngIf="type.name == 'image'"
                                      class="pdf"
                                      [src]="'/assets/icons/profile/pdf.svg'">
                            </svg-icon>
                            <div>
                                <h3>{{ 'MARKETPLACE_DETAIL_TYPE_' + type.name | translate }}</h3>
                                <p>{{ 'MARKETPLACE_DETAIL_DESC_TYPE_' + type.name | translate }}</p>
                            </div>
                        </div>
                    </ion-col>
                </ng-container>
            </ion-row>

        </ion-grid>

        <ng-container *ngIf="isPreview">

            <p *ngIf="groupedDetailPreview.length == 0"
               class="description">
                <span [innerHtml]="marketplacePost.description | linkify | nl2br | safe:'html'"></span>
            </p>

            <ng-container *ngFor="let group of groupedDetailPreview; let groupIndex = index"
                          class="ion-no-padding">
                <ng-container *ngIf=" !group.menu && (!group.lang || group.lang == userLang)">
                    <ion-row [class]="'ion-no-padding group ' + group.type">

                        <ng-container *ngFor="let detail of group.details; let detailIndex = index">
                            <ion-col class="between ion-text-center"
                                     *ngIf="detail.type == 'to'">
                                <span>-</span>
                            </ion-col>
                            <ion-col *ngIf="detail.value"
                                     [class]="detail.type">
                                <ng-container [ngSwitch]="true">

                                    <ng-container *ngSwitchCase="detail.type == 'image'">
                                        <ng-container *ngIf="detail.options?.type != 'pdf'">
                                            <img [src]="plt.createSignedLink(detail.value.replace('_preview',''))">
                                            <ng-container *ngIf="detail.options.name">{{ detail.options.name }}</ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="detail.options?.type == 'pdf'">
                                            <a [href]="plt.createSignedLink(detail.value)"
                                               onclick="window.open(this.href, '_system');return false">
                                                <svg-icon [applyClass]="true"
                                                          [src]="'/assets/icons/profile/pdf.svg'">
                                                </svg-icon>
                                                <ng-container *ngIf="detail.options.name">{{ detail.options.name }}</ng-container>
                                                <ng-container *ngIf="!detail.options.name && detail.options.filename">{{ detail.options.filename }}</ng-container>
                                            </a>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="detail.type == 'from' || detail.type == 'to'">
                                        <span>{{ detail.value | datex:'MM/YYYY'}}</span>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="detail.type == 'paragraph'">
                                        <p [innerHtml]="detail.value | linkify | nl2br | safe:'html'"></p>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="detail.type == 'header'">
                                        <h3>{{ detail.value }}</h3>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="detail.type == 'link'">
                                        <ng-container *ngIf="(detail.value != marketplacePost.linkedin_profile && detail.value != marketplacePost.xing_profile) || detail.options.name">
                                            <a (click)="openLink(detail.value, detail.options.target)"
                                               class="pointer">
                                                <ng-container *ngIf="detail.options.name">{{ detail.options.name }}</ng-container>
                                                <ng-container *ngIf="!detail.options.name">{{ detail.value }}</ng-container>
                                            </a>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngSwitchDefault>
                                        <span [innerHTML]="detail.value | nl2br"></span>
                                    </ng-container>

                                </ng-container>

                            </ion-col>
                            <ion-col *ngIf="!detail.value && detail.type == 'to'"
                                     [class]="detail.type">
                                {{ 'CV_TO_DEFAULT' | translate }}
                            </ion-col>
                        </ng-container>

                    </ion-row>
                    <ng-container *ngIf="groupIndex < (groupedDetailPreview.length - 1) && group.line">
                        <hr />
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>

    </div>
</div>

<!-- fab placed to the bottom end -->
<ion-fab *ngIf="(user?.id == marketplacePost.participant?.user_id || !marketplacePost.id) && plt.sizeSm && editable && loaded"
         vertical="bottom"
         horizontal="end"
         slot="fixed"
         (click)="togglePreview()">
    <ion-fab-button color="secondary">
        <ng-container *ngIf="!isPreview">
            {{ 'BUTTON_SHOW_PREVIEW_SHORT' | translate }}
        </ng-container>
        <ng-container *ngIf="isPreview">
            {{ 'BUTTON_SHOW_PREVIEW_SHORT_DISABLE' | translate }}
        </ng-container>
    </ion-fab-button>
</ion-fab>

<ion-footer *ngIf="!((user?.id == marketplacePost.participant?.user_id || !marketplacePost.id) && editable) && plt.sizeSm && !event?.disableInteraction">
    <ion-row class="action-buttons likes two ion-no-padding">
        <ion-col *ngIf="!user?.selected_participant.invisible"
                 size="6"
                 class="ion-no-padding">
            <ion-button (click)="toggleLike(marketplacePost)"
                        color="white"
                        class="full">
                <svg-icon slot="start"
                          [applyClass]="true"
                          src="/assets/icons/ico-like.svg"
                          [ngClass]="{'active': isLiked(marketplacePost)}"
                          class="like">
                </svg-icon> {{ 'MARKETPLACE_POST_LIKE' | trns }}
                <ion-spinner *ngIf="likeLoading"
                             name="dots">
                </ion-spinner>
            </ion-button>
        </ion-col>
        <ion-col class="ion-no-padding">
            <ion-button (click)="openComments()"
                        color="white"
                        class="full">
                <svg-icon slot="start"
                          [applyClass]="true"
                          src="/assets/icons/ico-message.svg"
                          class="like">
                </svg-icon> {{ 'MARKETPLACE_POST_COMMENTS' | trns }}
            </ion-button>
        </ion-col>
    </ion-row>
</ion-footer>

<ion-footer *ngIf="(user?.id == marketplacePost.participant?.user_id || !marketplacePost.id) && plt.sizeSm && editable">
    <ion-row class="action-buttons two ion-no-padding">
        <ion-col size="6"
                 class="ion-no-padding">
            <ion-button class="btn-close"
                        (click)="backClicked()">
                {{ 'BUTTON_CANCEL' | translate }}
            </ion-button>
        </ion-col>
        <ion-col size="6"
                 class="ion-no-padding">
            <ion-button color="primary"
                        [disabled]="sending || uploading"
                        (click)="closeSaved()">
                {{ 'BUTTON_SAVE' | translate }}
            </ion-button>
        </ion-col>
    </ion-row>
</ion-footer>